import React, { useEffect, useState } from "react";
import { getAllPlansData, getDashboardDataApi, getOwenrDetailById } from "../../Apis";
import ReCharts from "../../Components/Recharts/reCharts";
import { SERVER_ENDPOINT } from "../../utils/baseurl";
import img from '../../Assets/image/img-profile.jpg';
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import Loader from '../../Components/common/loader/Loader'
const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState("");
  const [ownerId, setOwnerId] = useState("");
  const [ownerData, setOwnerData] = useState("");
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(true);
  const [plans,setPlans] =useState([])
  const getDashboardDetails = async (page, limit) => {
    try {
      const res = await getDashboardDataApi(page, limit);
      if (res?.status === 200 || res?.status === 201) {
        console.log('data',res?.data)
        setDashboardData(res?.data);
        setPageCount(Math.ceil(res?.data?.allCount / limit))
        setLoading(false)
      }
      console.log(res, "respsdnfknsdityuis");
    } catch (err) {
      console.log(err);
    }
  };
  const handlePageClick = (e) => {
    setLoading(true);
    const selectedPage = e.selected + 1;
    setCurrentPage(selectedPage);
    getDashboardDetails(selectedPage, limit);
  };
  const getPlansinfo =async () => {
    try{
      const res = await getAllPlansData();
      console.log(res,"response")
      if(res?.status === 200 || res?.status === 201){
        setPlans(res.data.allPlans);
      }
    }catch(err){
      console.log(err)
    }
  }
  const getSinglePrice =(name)=>{
    console.log(name,"namesingle")
    if(name){
      let d = name?.split("+")[1]?.split(" ")[1] || 0
      let total = parseInt(plans[0]?.planPriceId?.price) + parseInt(plans[1]?.planPriceId?.price * d) ; 
      return total
    }else{
      return "-"
    }
  }
  const getOwnerDetails = async (id) => {
    try {
      const res = await getOwenrDetailById(id);
      console.log(res, "tiuioasdnfadsfi")
      if (res?.status === 200 || res?.status === 201) {
        setOwnerData(res?.data);
      }
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    getDashboardDetails(currentPage, limit);
    getPlansinfo()
  }, []);
  useEffect(() => {
    if (ownerId) {
      getOwnerDetails(ownerId)
    }
  }, [ownerId])
  return (
    <>
      <main className="py-6">
        {/* <!-- Container --> */}
        <div className="container-fluid">
          <div className="row g-4 mb-4">
            <div className="">
              <div className="card">
                <div className="card-header d-flex align-items-center">
                  <div className="row" style={{ width: "100%" }}>
                    <h5 className="mb-0">Subscription</h5>
                    <div className="col-12" style={{ width: "100%" }}>
                      <ReCharts />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-4">
              <div id="chart-line" data-height="300"></div>
            </div>
          </div>

          {/* <!-- Card stats --> */}
          <div className="row g-6 mb-6">
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <span className="h5 text-muted font-semibold d-block mb-2">
                        {" "}
                        Total Account Owners
                      </span>
                      <span className="h3 font-bold mb-0">
                        {loading ? <Loader /> : dashboardData?.allCount}
                      </span>
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape bg-info text-white text-lg rounded-circle">
                        <i className="bi bi-people"></i>
                      </div>
                    </div>
                  </div>
{/* 
                  <div className="mt-2 mb-0 text-sm">
                    <span className="badge badge-pill bg-soft-danger text-danger me-2">
                      <i className="bi bi-arrow-down me-1"></i>-10%
                    </span>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <span className="h5 text-muted font-semibold d-block mb-2">
                        Total HealthCare Providers
                      </span>
                      <span className="h3 font-bold mb-0">
                        {loading ? <Loader /> : dashboardData?.allProviders}
                      </span>
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape bg-warning text-white text-lg rounded-circle">
                        <i className="bi bi-heart-pulse"></i>
                      </div>
                    </div>
                  </div>

                  {/* <div className="mt-2 mb-0 text-sm">
                    <span className="badge badge-pill bg-soft-success text-success me-2">
                      <i className="bi bi-arrow-up me-1"></i>15%
                    </span>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <span className="h5 text-muted font-semibold d-block mb-2">
                        Total Patients
                      </span>
                      <span className="h3 font-bold mb-0">
                        {loading ? <Loader /> : dashboardData?.allPatients}
                      </span>
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape bg-primary text-white text-lg rounded-circle">
                        <i className="bi bi-person-heart"></i>
                      </div>
                    </div>
                  </div>
{/* 
                  <div className="mt-2 mb-0 text-sm">
                    <span className="badge badge-pill bg-soft-success text-success me-2">
                      <i className="bi bi-arrow-up me-1"></i>23%
                    </span>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <span className="h5 text-muted font-semibold d-block mb-2">
                        Total Subscription Earning
                      </span>
                      <span className="h3 font-bold mb-0">&euro;750.90</span>
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape bg-tertiary text-white text-lg rounded-circle">
                        <i className="bi bi-currency-euro"></i>
                      </div>
                    </div>
                  </div>

                  {/* <div className="mt-2 mb-0 text-sm">
                    <span className="badge badge-pill bg-soft-success text-success me-2">
                      <i className="bi bi-arrow-up me-1"></i>30%
                    </span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="row g-6 mb-4">
            <div className="col-xl-9">
              <div className="card">
                <div className="card-header d-flex align-items-center border-bottom">
                  <h5 className="mb-0">All Account Owners</h5>
                  <div className="ms-auto text-end">
                    <a
                      href="/pages/Dashboard/ViewAllAccountHolder"
                      className="text-sm font-semibold"
                    >
                      See all
                    </a>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table table-hover table-nowrap">
                    <thead className="table-light">
                      <tr>
                        <th scope="col">S.No.</th>
                        <th scope="col">Name</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? <tr align="center">
                        <td colspan="8">
                          <Loader />
                        </td>
                      </tr> : Boolean(dashboardData?.allOwners?.length) &&
                      dashboardData?.allOwners?.map((v, i) => {
                        return (
                          <tr>
                          <td>{(currentPage - 1) * limit + (i + 1)}</td>
                            <td>
                              <img
                                alt="..."
                                src={`${SERVER_ENDPOINT}/${v?.profilePic}`}
                                onError={(event) => {
                                  event.target.src = img;
                                  event.onerror = null;
                                }}
                                className="avatar avatar-sm rounded-circle me-2"
                              />
                              <a
                                className="text-heading font-semibold"
                                href="#"
                              >
                                {v?.salutation + " " + v?.firstName + " " + v?.lastName}
                              </a>
                            </td>
                            {console.log(v,"VALIDIDSIFIDS")}
                            <td>{moment(v?.createdAt?.split("T")[0]).format("DD/MM/YYYY")}</td>
                            <td>
                              <span className="badge badge-lg badge-dot">
                                {
                                  console.log(v?.status , "statuautsf")
                                }
                                <i className="bg-success"></i>{v?.status == "complete" ? "Subscribed" : "Trial"}
                              </span>
                            </td>
                            <td className="text-end">
                              <a
                                href="#modalholder"
                                className="btn btn-sm btn-primary"
                                data-bs-toggle="modal"
                                onClick={() => setOwnerId(v?._id)}
                              >
                                {" "}
                                View
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                      {/* <tr>
                        <td>
                          <img
                            alt="..."
                            src="/adminkit/img/logos/adminuser.png"
                            className="avatar avatar-sm rounded-circle me-2"
                          />
                          <a className="text-heading font-semibold" href="#">
                            Kristin Watson
                          </a>
                        </td>
                        <td>23-01-2022</td>
                        <td>
                          <span className="badge badge-lg badge-dot">
                            <i className="bg-success"></i>Active
                          </span>
                        </td>
                        <td className="text-end">
                          <a
                            href="#modalholder"
                            className="btn btn-sm btn-primary"
                            data-bs-toggle="modal"
                          >
                            {" "}
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            alt="..."
                            src="/adminkit/img/logos/adminuser.png"
                            className="avatar avatar-sm rounded-circle me-2"
                          />
                          <a className="text-heading font-semibold" href="#">
                            Robert Fox
                          </a>
                        </td>
                        <td>23-01-2022</td>
                        <td>
                          <span className="badge badge-lg badge-dot">
                            <i className="bg-danger"></i>Deactive
                          </span>
                        </td>
                        <td className="text-end">
                          <a
                            href="#modalholder"
                            className="btn btn-sm btn-primary"
                            data-bs-toggle="modal"
                          >
                            {" "}
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            alt="..."
                            src="/adminkit/img/logos/adminuser.png"
                            className="avatar avatar-sm rounded-circle me-2"
                          />
                          <a className="text-heading font-semibold" href="#">
                            Cody Fisher
                          </a>
                        </td>
                        <td>23-01-2022</td>
                        <td>
                          <span className="badge badge-lg badge-dot">
                            <i className="bg-danger"></i>Deactive
                          </span>
                        </td>
                        <td className="text-end">
                          <a
                            href="#modalholder"
                            className="btn btn-sm btn-primary"
                            data-bs-toggle="modal"
                          >
                            {" "}
                            View
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            alt="..."
                            src="/adminkit/img/logos/adminuser.png"
                            className="avatar avatar-sm rounded-circle me-2"
                          />
                          <a className="text-heading font-semibold" href="#">
                            Theresa Webb
                          </a>
                        </td>
                        <td>23-01-2022</td>
                        <td>
                          <span className="badge badge-lg badge-dot">
                            <i className="bg-danger"></i>Deactive
                          </span>
                        </td>
                        <td className="text-end">
                          <a
                            href="#modalholder"
                            className="btn btn-sm btn-primary"
                            data-bs-toggle="modal"
                          >
                            {" "}
                            View
                          </a>
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="card">
                <div className="card-body">
                  <div className="card-title d-flex align-items-center">
                    <h5 className="mb-0">Recent Activity</h5>
                    <div className="ms-auto text-end">
                      <a
                        href="/pages/Dashboard/all-activity"
                        className="text-sm font-semibold"
                      >
                        See all
                      </a>
                    </div>
                  </div>
                  <div className="list-group gap-4">
                    <div className="list-group-item d-flex align-items-center  ">
                      <div className="me-4">
                        <div className="avatar rounded-circle">
                          <img
                            alt="..."
                            src="/adminkit/img/logos/adminuser.png"
                          />
                        </div>
                      </div>
                      <div className="flex-1">
                        <div className="d-flex align-items-center mb-1">
                          <a href="#" className="d-block h6 stretched-link">
                            Robert Fox
                          </a>
                          <span className="text-muted text-xs ms-2">1 hr ago</span>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="w-3/4 text-sm text-muted line-clamp-1 me-auto">
                            Some text related to the activity
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-group-item d-flex align-items-center  ">
                      <div className="me-4">
                        <div className="avatar rounded-circle">
                          <img
                            alt="..."
                            src="/adminkit/img/logos/adminuser.png"
                          />
                        </div>
                      </div>
                      <div className="flex-1">
                        <div className="d-flex align-items-center mb-1">
                          <a href="#" className="d-block h6 stretched-link">
                            Robert Fox
                          </a>
                          <span className="text-muted text-xs ms-2">1 hr ago</span>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="w-3/4 text-sm text-muted line-clamp-1 me-auto">
                            Some text related to the activity
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-group-item d-flex align-items-center  ">
                      <div className="me-4">
                        <div className="avatar rounded-circle">
                          <img
                            alt="..."
                            src="/adminkit/img/logos/adminuser.png"
                          />
                        </div>
                      </div>
                      <div className="flex-1">
                        <div className="d-flex align-items-center mb-1">
                          <a href="#" className="d-block h6 stretched-link">
                            Robert Fox
                          </a>
                          <span className="text-muted text-xs ms-2">1 hr ago</span>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="w-3/4 text-sm text-muted line-clamp-1 me-auto">
                            Some text related to the activity
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {modal} */}
        <div
          className="modal fade"
          id="modalholder"
          tabIndex={-1}
          aria-labelledby="modalholder"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content shadow-3">
              <div className="modal-header mb-5">
                <div className="h3 text-center"> Account Holder Info</div>
                <button
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                {/* Text */}
                <div className="container-fluid max-w-screen-md vstack gap-5 p-5 ">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="text-center">
                        <a
                          href="#"
                          className="avatar w-48 h-48 border border-body border-4 rounded shadow position-relative"
                        >
                          <img
                            alt="..."
                            src={`${SERVER_ENDPOINT}/${ownerData?.owner?.profilePic}`}
                            onError={(event) => {
                              event.target.src = img;
                              event.onerror = null;
                            }}
                            className="rounded"
                          />
                        </a>
                      </div>
                    </div>
{console.log(ownerData,"ownerekfjdsklf")}
                    <div className="col-sm-8 mx-auto font-bolder">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="pb-2">Account Holder Name</div>
                          <div className="pb-2">Date</div>
                          <div className="pb-2">Plan Name</div>
                          <div className="pb-2">Price</div>
                          <div className="pb-2">Status</div>
                        </div>
                        <div className="col-sm-6 text-primary">
                          <div className="pb-2">{ownerData?.owner && ownerData?.owner?.salutation + " " + ownerData?.owner?.firstName + " " + ownerData?.owner?.lastName}</div>
                          <div className="pb-2">{ownerData?.owner && moment(ownerData?.owner?.createdAt?.split("T")[0]).format("DD/MM/YYYY")}</div>
                          <div className="pb-2">{ownerData?.userSubscription?.planeName ? ownerData?.userSubscription?.planeName: "-"}</div>
                          <div className="pb-2">&euro;{getSinglePrice(ownerData?.userSubscription?.planeName )}</div>
                          <div className="text-success">{ownerData?.userSubscription?.status == "complete" ? "Subscribed":"Trial"}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className="ps-5">
        <ReactPaginate
        nextLabel="Next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="< Previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
        />
        </div>
      </main>
      
    </>
  );
};

export default Dashboard;
