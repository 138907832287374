import React, { useState } from "react";
import Navbar from "../Components/Headers/Navbar";
import Sidebar from "../Components/Headers/Sidebar";
import Dashboard from "../Pages/Dashboard/Dashboard";
import AllActivity from "../Pages/Dashboard/AllActivity";
import ViewAllAccountHolder from "../Pages/Dashboard/ViewAllAccountHolder";
import AccountView from "../Pages/AccountOwner/TableView";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Patient from "../Pages/Patients/Patient";
import Billing from "../Pages/Subscription/Billing";
import Report from "../Pages/MIS/Report";
import Profile from "../Pages/Profiles/Profile";
import Login from "../Pages/Authentication/LoginPage/Login";
import PrivateRoute from "../Components/context/PrivateRoute";
import RecoverPassword from "../Pages/Authentication/RecoverPassword/RecoverPassword";
import EditProfile from "../Pages/Authentication/EditProfile/EditProfile";
import { getToken } from "../utils/helperFunctions";
import AddSubscription from "../Pages/Subscription/AddSubscription";
import ErrorPage from "../Components/common/ErrorPage";
import ProductView from "../Pages/ProductView/ProductView";


const MainRouter = () => {
  
  const [showNavSidebar, setShowNavSidebar] = useState(true);

  const pageData = [
    { pathname: "/", name: "Dashboard", navbar: true, isPrivate: true, component: <Dashboard /> },
    { pathname: "/pages/Dashboard/all-activity", name: "Recent Activity", navbar: true, isPrivate: true, component: <AllActivity/> },
    { pathname: "/pages/Dashboard/ViewAllAccountHolder", name: "All Account Holders", navbar: true, isPrivate: true, component: <ViewAllAccountHolder/> },
    { pathname: "/pages/subscription/billing", name: " Products", navbar: true, isPrivate: true, component: <Billing /> },
    { pathname: "/pages/subscription/AddSubscription", name: "Subscription Products", navbar: true, isPrivate: true, component: <AddSubscription/> },
    { pathname: "/pages/MIS/report", name: "Report", navbar: true, isPrivate: true, component: <Report /> },
    { pathname: "/proudctView", name: "Products", navbar: true, isPrivate: true, component: <ProductView /> },
    { pathname: "/pages/profiles/profile", name: "Profile", navbar: true, isPrivate: true, component: <Profile /> },
    { pathname: "/updatepassword", name: "Update Password", navbar: true, isPrivate: true, component: <RecoverPassword /> },
    { pathname: "/editprofile", name: "Edit Profile", navbar: true, isPrivate: true,component: <EditProfile /> },
    { pathname: "/login", name: "Edit Profile", navbar: false, isPrivate: false, component: <Login /> },
    { pathname: "*", name: "", navbar: false, isPrivate: false, component: <ErrorPage /> }
  ];
  return (
    <>
      <BrowserRouter>
        <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
          {Boolean(getToken()) && <Sidebar />}
          <div className="flex-lg-1 h-screen overflow-y-lg-auto">
            {Boolean(getToken()) && <Navbar pageData={pageData} />}
            <Routes>
              {pageData?.map((el) => {
                return el.isPrivate ? (
                  <Route
                    path={el.pathname}
                    element={<PrivateRoute>{el.component}</PrivateRoute>}
                  />
                ) : (
                  <Route path={el.pathname} element={el.component} />
                );
              })}
              {/* <Route path="/login" element={<Login funcNavSidebar={setShowNavSidebar}/>}/>
                <Route path="/" element={<PrivateRoute><Dashboard funcNavSidebar={setShowNavSidebar}/></PrivateRoute>}/>
                {/* <Route path="/pages/owner/table-view" element={<AccountView/>}/>
                <Route path="/pages/health/healthcareprovider" element={<HealthCareProvider/>}/>
                <Route path="/pages/patients/patientlisting" element={<Patient/>}/>  
                <Route path="/pages/settings/subscription/billing" element={<PrivateRoute><Billing/></PrivateRoute>}/>
                <Route path="/pages/MIS/report" element={<PrivateRoute><Report/></PrivateRoute>}/> 
                <Route path="/pages/profiles/profile" element={<PrivateRoute><Profile/></PrivateRoute>}/>
                <Route path="/updatepassword" element={<PrivateRoute><RecoverPassword funcNavSidebar={setShowNavSidebar}/></PrivateRoute>}/>
                <Route path="/editprofile" element={<PrivateRoute><EditProfile /></PrivateRoute>}/> */}
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </>
  );
};

export default MainRouter;
