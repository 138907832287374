import React from 'react';
// import './Error.css';
import img from '../../Assets/image/errorImage.webp';
const ErrorPage = () => {
  return (
    <>
    <img src={img} alt="" />
  </>
  )
}

export default ErrorPage