import { postApiWithoutToken, patchPasswordApi, patchAdminProfileApi,  getAdminProfileApi, updateImageProfileApi, 
  postSubscriptionPlanApi, getAllSubscriptionPlansData, getApi, patchApi, patchApiWithoutToken } from "./api-interface";

/* Login APIs */
export const loginApi = (payload) => {
    return postApiWithoutToken("/admin/auth/login", payload)
}


/* Change Password APIs */
export const changePasswordApi = (payload) => {
  return patchPasswordApi("/user/changePassword", payload)
}

export const updateAccountOwnerProfileApi = (payload) => {
  return patchApi("/user/update", payload)
}
/* Update Admin Profile APIs */
export const updateAdminProfileApi = (payload) => {
  return patchAdminProfileApi("/user/update", payload)
}

/* Admin User Profile APIs */
export const AdminProfileApi = () => {
  return getAdminProfileApi("/user/byAuth")
} 



export const imageProfileApi = (payload) => {
  return updateImageProfileApi("/common/uploads/file", payload)
} 



/* Create Subscription Plan Api's */
export const createSubscriptionPlan = (payload) => {
  return postSubscriptionPlanApi("/common/plan/create", payload)
}

export const updateSubscriptionPlan=(payload)=>{
  return patchApi(`/common/plan/update`,payload);
}

/* Get All Subscription Plans Data */
export const getAllPlansData = () => {
  return getAllSubscriptionPlansData("/common/plan/all")
}


export const getDashboardDataApi =(page="",limit="",data="")=>{
  return getApi(`/admin/dashboard/all?search=${data}&page=${page}&limit=${limit}`);
}

export const getOwenrDetailById=(id)=>{
  if(!id) return ;
  return getApi(`/admin/accountOwner/byId?id=${id}`)
}


export const forgotPasswordApi=(payload)=>{
  return patchApiWithoutToken(`/user/forgotPassword`,payload);
}


export const getPlanByIdApi = (id)=>{
  if(!id) return;
  return getApi(`/common/plan/byId?id=${id}`);
}

export const updatePriceApi =(payload)=>{
  return patchApi(`/common/plan/updatePrice`,payload);
}

export const downloadAccountOwners = ()=>{
  return getApi(`/admin/exports/accountOwners`);
}

export const downloadPateints  =()=>{
  return getApi(`/admin/exports/patients`);
}

export const downloadProviders = ()=>{
  return getApi(`/admin/exports/providers`);
}