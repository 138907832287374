import React from 'react';

const Profile = () => {

  return (
   
        <>
                    <header className="bg-surface-secondary">
                {/* <!-- Cover --> */}
                <div className="bg-cover"
                    style={{height: "300px", backgroundImage: "url(/adminkit/img/covers/imgProfile.jpg)", backgroundPosition: "center center"}}>
                </div>
                {/* <!-- Info --> */}
                <div className="container-fluid max-w-screen-xl">
                    <div className="row g-0">
                        <div className="col-auto">
                            <a href="#"
                                className="avatar w-40 h-40 border border-body border-4 rounded-circle shadow mt-n16">
                                <img alt="..." src="/adminkit/img/people/img-profile.jpg" className="rounded-circle"/>
                            </a>
                        </div>
                        <div className="col ps-4 pt-4">

                            {/* <!-- Pretitle --> */}
                            <h6 className="text-xs text-uppercase text-muted mb-1">
                                UI Designer
                            </h6>

                            {/* <!-- Title --> */}
                            <h1 className="h2">
                                Tahlia Mooney
                            </h1>

                            <div className="d-flex gap-2 flex-wrap mt-3 d-none d-sm-block">
                                <a href="#"
                                    className="bg-white bg-opacity-50 bg-opacity-100-hover border rounded px-3 py-1 font-semibold text-heading text-xs">UI/UX</a>
                                <a href="#"
                                    className="bg-white bg-opacity-50 bg-opacity-100-hover border rounded px-3 py-1 font-semibold text-heading text-xs">Mobile
                                    Apps</a>
                                <a href="#"
                                    className="bg-white bg-opacity-50 bg-opacity-100-hover border rounded px-3 py-1 font-semibold text-heading text-xs">UI
                                    Research</a>
                            </div>
                        </div>
                        <div className="col-12 col-md-auto mt-2 mt-md-0 mb-md-3 hstack gap-2 mt-4 mt-sm-0">
                            <a href="#" className="btn btn-sm btn-square btn-neutral">
                                <i className="bi bi-envelope"></i>
                            </a>
                            <a href="#" className="btn btn-sm btn-square btn-neutral">
                                <i className="bi bi-bell-fill"></i>
                            </a>
                            <a href="#" className="btn btn-sm btn-primary d-block d-md-inline-block ms-auto ms-md-0">
                                Follow
                            </a>
                        </div>
                    </div>
                    {/* <!-- Nav --> */}
                    <ul className="nav nav-tabs overflow-x ms-1 mt-4">
                        <li className="nav-item">
                            <a href="#!" className="nav-link active font-bold">
                                My profile
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="#!" className="nav-link">
                                Settings
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="#!" className="nav-link">
                                Integrations
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="#!" className="nav-link">
                                Security
                            </a>
                        </li>
                    </ul>
                </div>
            </header>

<main className="py-6 bg-surface-secondary">

<div className="container-fluid max-w-screen-xl">
    <div className="row g-6">
        <div className="col-xl-8">
            <div className="vstack gap-6">
                <div className="card">
                    <div className="card-body">
                        <h5 className="mb-3">Biography</h5>
                        <p className="text-sm lh-relaxed mb-4">
                            Personal profiles are the perfect way for you to grab their attention and
                            persuade recruiters to continue reading your CV because you’re telling them
                            from the off exactly why they should hire you. Of course, you’ll need to
                            know
                            how to write an effective statement first, but we’ll get on to that in a
                            bit.
                        </p>
                        <p className="text-sm lh-loose mb-4">
                            When we strive to become better than we are everything around us becomes
                            better too. This is a wider card with supporting text below. Personal
                            profiles are the perfect way for you to grab their attention and persuade
                            recruiters to continue reading your CV because you’re telling them from the
                            off exactly why they should hire you. Of course, you’ll need to know
                            how to write an effective statement first, but we’ll get on to that in a
                            bit.
                        </p>
                        <a href="#" className="link-primary font-semibold text-sm">Read more</a>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <h5 className="mb-6">Recent Activity</h5>
                        <div className="list-group list-group-flush list-group-borderless ms-4">
                            <div className="list-group-item px-2 py-0">
                                <div className="border-start">
                                    <div className="d-flex ms-n6 pb-6">
                                        <div className="flex-none me-3">
                                            <div
                                                className="icon icon-shape text-base w-12 h-12 bg-soft-primary text-primary rounded-circle">
                                                <i className="bi bi-file-earmark-pdf-fill"></i>
                                            </div>
                                        </div>
                                        <div>
                                            <small className="d-block mb-1 text-muted">2 hrs ago</small>
                                            <div>You've uploaded <span
                                                    className="font-bold">documentation.pdf</span> to the
                                                <span className="font-bold">Webpixels</span> project</div>
                                            <div className="d-flex gap-2 mt-2">
                                                <div
                                                    className="position-relative bg-light border border-dashed border-warning-hover rounded-pill">
                                                    <div className="py-2 px-3 d-flex align-items-center">
                                                        <div className="me-2">
                                                            <i className="bi bi-image text-warning"></i>
                                                        </div>
                                                        <div className="flex-fill">
                                                            <a href="#"
                                                                className="d-block font-semibold text-xs text-muted text-warning-hover stretched-link">documentation.pdf</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="list-group-item px-2 py-0">
                                <div className="border-start">
                                    <div className="d-flex ms-n6 pb-6">
                                        <div className="flex-none me-3">
                                            <div
                                                className="icon icon-shape text-base w-12 h-12 bg-soft-primary text-primary rounded-circle">
                                                <i className="bi bi-file-earmark-pdf-fill"></i>
                                            </div>
                                        </div>
                                        <div className="flex-fill">
                                            <small className="d-block mb-1 text-muted">4 hrs ago</small>
                                            <div className="text-sm">
                                                <span className="text-heading text-sm font-bold">Mike</span>
                                                added a new comment to your task:
                                            </div>
                                            <div className="mt-2">
                                                <p className="text-sm text-muted">
                                                    On the other hand, we denounce with righteous
                                                    indignation and dislike men who are so beguiled and
                                                    demoralized by the charms of pleasure of the moment,
                                                    so blinded by desire, that they cannot foresee.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="list-group-item px-2 py-0">
                                <div className="border-start">
                                    <div className="d-flex ms-n6 pb-6">
                                        <div className="flex-none me-3">
                                            <div
                                                className="icon icon-shape text-base w-12 h-12 bg-soft-primary text-primary rounded-circle">
                                                <i className="bi bi-file-earmark-pdf-fill"></i>
                                            </div>
                                        </div>
                                        <div className="flex-fill">
                                            <small className="d-block mb-1 text-muted">4 hrs ago</small>
                                            <div className="text-sm">
                                                Heather added <span className="font-bold">4 images</span> to
                                                the <span className="font-bold">Team</span> album:
                                            </div>
                                            <div className="mt-2 d-flex gap-2 scrollable-x">
                                                <div className="col-auto">
                                                    <img alt="..." src="/img/media/img-3.jpg"
                                                        className="rounded w-auto h-16"/>
                                                </div>
                                                <div className="col-auto">
                                                    <img alt="..." src="/img/media/img-4.jpg"
                                                        className="rounded w-auto h-16"/>
                                                </div>
                                                <div className="col-auto">
                                                    <img alt="..." src="/img/media/img-5.jpg"
                                                        className="rounded w-auto h-16"/>
                                                </div>
                                                <div className="col-auto">
                                                    <img alt="..." src="/img/media/img-6.jpg"
                                                        className="rounded w-auto h-16"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="list-group-item px-2 py-0">
                                <div className="border-start">
                                    <div className="d-flex ms-n6 pb-6">
                                        <div className="flex-none me-3">
                                            <div
                                                className="icon icon-shape text-base w-12 h-12 bg-soft-primary text-primary rounded-circle">
                                                <i className="bi bi-file-earmark-pdf-fill"></i>
                                            </div>
                                        </div>
                                        <div className="flex-fill">
                                            <small className="d-block mb-1 text-muted">4 hrs ago</small>
                                            <div className="text-sm">
                                                Heather added <span className="font-bold">Anna</span> to the
                                                <span className="font-bold">Clever</span> team.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="list-group-item px-2 py-0">
                                <div className="border-start">
                                    <div className="d-flex ms-n6 pb-6">
                                        <div className="flex-none me-3">
                                            <div
                                                className="icon icon-shape text-base w-12 h-12 bg-soft-primary text-primary rounded-circle">
                                                <i className="bi bi-file-earmark-pdf-fill"></i>
                                            </div>
                                        </div>
                                        <div className="flex-fill">
                                            <small className="d-block mb-1 text-muted">4 hrs ago</small>
                                            <div className="text-sm">
                                                Heather created the tags
                                                <div className="d-inline-block mx-1">
                                                    <a href="#"
                                                        className="badge rounded-pill bg-danger bg-opacity-20 bg-opacity-100-hover text-danger text-white-hover">Bugs</a>
                                                    <a href="#"
                                                        className="badge rounded-pill bg-success bg-opacity-20 bg-opacity-100-hover text-success text-white-hover">Showcase</a>
                                                </div>
                                                for the <span className="font-bold">Clever</span> project
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="list-group-item px-2 py-0">
                                <div>
                                    <div className="d-flex ms-n6 pb-6">
                                        <div className="flex-none me-3">
                                            <div
                                                className="icon icon-shape text-base w-12 h-12 bg-soft-primary text-primary rounded-circle">
                                                <i className="bi bi-file-earmark-pdf-fill"></i>
                                            </div>
                                        </div>
                                        <div className="flex-fill">
                                            <small className="d-block mb-1 text-muted">2 hrs ago</small>
                                            <div>
                                                You've uploaded <span
                                                    className="font-bold">documentation.pdf</span> to the
                                                <span className="font-bold">Webpixels</span> project
                                            </div>
                                            <div className="mt-2 scrollable-x overflow-hidden">
                                                <div
                                                    className="d-inline-block position-relative bg-light border border-dashed border-tertiary-hover rounded-pill">
                                                    <div className="py-2 px-3 d-flex align-items-center">
                                                        <div className="me-2">
                                                            <i className="bi bi-image text-tertiary"></i>
                                                        </div>
                                                        <div className="flex-fill">
                                                            <a href="#"
                                                                className="d-block font-semibold text-xs text-muted text-tertiary-hover stretched-link">design.fig</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="d-inline-block position-relative bg-light border border-dashed border-warning-hover rounded-pill">
                                                    <div className="py-2 px-3 d-flex align-items-center">
                                                        <div className="me-2">
                                                            <i className="bi bi-image text-warning"></i>
                                                        </div>
                                                        <div className="flex-fill">
                                                            <a href="#"
                                                                className="d-block font-semibold text-xs text-muted text-warning-hover stretched-link">readme.md</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h5 className="mb-4">My Files</h5>
                    <div className="row g-5">
                        <div className="col-lg-4 col-sm-6">
                            <div className="card shadow-4-hover">
                                <div className="p-2">
                                    <a href="#" className="d-block">
                                        <img className="img-fluid rounded-2" alt="..."
                                            src="/adminkit/img/media/img-800x800-1.jpg" />
                                    </a>
                                </div>
                                <div className="px-4 pt-2 pb-4">
                                    <h6 className="font-semibold mb-2">Website Redesign</h6>
                                    <div className="d-flex align-items-center">
                                        <div className="text-xs text-muted">
                                            <i className="bi bi-lock me-1"></i>Private
                                        </div>
                                        <div className="text-xs text-muted ms-auto">
                                            3 hrs ago
                                        </div>
                                    </div>
                                    <span className="d-block"></span>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="card shadow-4-hover">
                                <div className="p-2">
                                    <a href="#" className="d-block">
                                        <img className="img-fluid rounded-2" alt="..."
                                            src="/adminkit/img/media/img-800x800-2.jpg" />
                                    </a>
                                </div>
                                <div className="px-4 pt-2 pb-4">
                                    <h6 className="font-semibold mb-2">Website Redesign</h6>
                                    <div className="d-flex align-items-center">
                                        <div className="text-xs text-muted">
                                            <i className="bi bi-lock me-1"></i>Private
                                        </div>
                                        <div className="text-xs text-muted ms-auto">
                                            3 hrs ago
                                        </div>
                                    </div>
                                    <span className="d-block"></span>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="card shadow-4-hover">
                                <div className="p-2">
                                    <a href="#" className="d-block">
                                        <img className="img-fluid rounded-2" alt="..."
                                            src="/adminkit/img/media/img-800x800-3.jpg" />
                                    </a>
                                </div>
                                <div className="px-4 pt-2 pb-4">
                                    <h6 className="font-semibold mb-2">Website Redesign</h6>
                                    <div className="d-flex align-items-center">
                                        <div className="text-xs text-muted">
                                            <i className="bi bi-lock me-1"></i>Private
                                        </div>
                                        <div className="text-xs text-muted ms-auto">
                                            3 hrs ago
                                        </div>
                                    </div>
                                    <span className="d-block"></span>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="card shadow-4-hover">
                                <div className="p-2">
                                    <a href="#" className="d-block">
                                        <img className="img-fluid rounded-2" alt="..."
                                            src="/adminkit/img/media/img-800x800-4.jpg" />
                                    </a>
                                </div>
                                <div className="px-4 pt-2 pb-4">
                                    <h6 className="font-semibold mb-2">Website Redesign</h6>
                                    <div className="d-flex align-items-center">
                                        <div className="text-xs text-muted">
                                            <i className="bi bi-lock me-1"></i>Private
                                        </div>
                                        <div className="text-xs text-muted ms-auto">
                                            3 hrs ago
                                        </div>
                                    </div>
                                    <span className="d-block"></span>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="card shadow-4-hover">
                                <div className="p-2">
                                    <a href="#" className="d-block">
                                        <img className="img-fluid rounded-2" alt="..."
                                            src="/adminkit/img/media/img-800x800-5.jpg" />
                                    </a>
                                </div>
                                <div className="px-4 pt-2 pb-4">
                                    <h6 className="font-semibold mb-2">Website Redesign</h6>
                                    <div className="d-flex align-items-center">
                                        <div className="text-xs text-muted">
                                            <i className="bi bi-lock me-1"></i>Private
                                        </div>
                                        <div className="text-xs text-muted ms-auto">
                                            3 hrs ago
                                        </div>
                                    </div>
                                    <span className="d-block"></span>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="card shadow-4-hover">
                                <div className="p-2">
                                    <a href="#" className="d-block">
                                        <img className="img-fluid rounded-2" alt="..."
                                            src="/adminkit/img/media/img-800x800-6.jpg" />
                                    </a>
                                </div>
                                <div className="px-4 pt-2 pb-4">
                                    <h6 className="font-semibold mb-2">Website Redesign</h6>
                                    <div className="d-flex align-items-center">
                                        <div className="text-xs text-muted">
                                            <i className="bi bi-lock me-1"></i>Private
                                        </div>
                                        <div className="text-xs text-muted ms-auto">
                                            3 hrs ago
                                        </div>
                                    </div>
                                    <span className="d-block"></span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-xl-4">
            <div className="vstack gap-6">
                <div className="card">
                    <div className="card-body">
                        <h5 className="mb-4">Location</h5>
                        <div className="d-flex align-items-center">
                            <i className="bi bi-geo-alt-fill me-2 text-muted"></i>
                            <a href="#" className="text-sm text-heading text-primary-hover">San Francisco,
                                California</a>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <h5 className="mb-4">Social</h5>
                        <div className="vstack gap-4">
                            <div className="d-flex align-items-center">
                                <i className="bi bi-globe me-2 text-muted"></i>
                                <a href="#"
                                    className="text-sm text-heading text-primary-hover">mywebsite.com</a>
                            </div>
                            <div className="d-flex align-items-center">
                                <i className="bi bi-journal-album me-2 text-muted"></i>
                                <a href="#"
                                    className="text-sm text-heading text-primary-hover">clever.mywebsite.com</a>
                            </div>
                            <div className="d-flex align-items-center">
                                <i className="bi bi-github me-2 text-muted"></i>
                                <a href="#" className="text-sm text-heading text-primary-hover">GitHub</a>
                            </div>
                            <div className="d-flex align-items-center">
                                <i className="bi bi-dribbble me-2 text-muted"></i>
                                <a href="#" className="text-sm text-heading text-primary-hover">Dribbble</a>
                            </div>
                            <div className="d-flex align-items-center">
                                <i className="bi bi-twitter me-2 text-muted"></i>
                                <a href="#" className="text-sm text-heading text-primary-hover">Twitter</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <h5 className="mb-4">Mutual connections</h5>
                        <div className="vstack gap-6">

                            <div className="d-flex align-items-center">
                                <div className="me-3">
                                    <img alt="..." className="avatar rounded-circle"
                                        src="/adminkit/img/people/img-3.jpg" />
                                </div>
                                <div className="flex-1">
                                    <a href="#"
                                        className="d-block font-semibold text-sm text-heading text-primary-hover">Robert
                                        Fox</a>
                                    <div className="text-xs text-muted line-clamp-1">
                                        Web Developer
                                    </div>
                                </div>
                                <div className="ms-auto text-end">
                                    <a href="#" className="btn btn-sm px-3 py-1 btn-neutral">Follow</a>
                                </div>
                            </div>

                            <div className="d-flex align-items-center">
                                <div className="me-3">
                                    <img alt="..." className="avatar rounded-circle"
                                        src="/adminkit/img/people/img-1.jpg" />
                                </div>
                                <div className="flex-1">
                                    <a href="#"
                                        className="d-block font-semibold text-sm text-heading text-primary-hover">Darlene
                                        Robertson</a>
                                    <div className="text-xs text-muted line-clamp-1">
                                        Web Developer
                                    </div>
                                </div>
                                <div className="ms-auto text-end">
                                    <a href="#" className="btn btn-sm px-3 py-1 btn-neutral">Follow</a>
                                </div>
                            </div>

                            <div className="d-flex align-items-center">
                                <div className="me-3">
                                    <img alt="..." className="avatar rounded-circle"
                                        src="/adminkit/img/people/img-2.jpg" />
                                </div>
                                <div className="flex-1">
                                    <a href="#"
                                        className="d-block font-semibold text-sm text-heading text-primary-hover">Theresa
                                        Webb</a>
                                    <div className="text-xs text-muted line-clamp-1">
                                        Web Developer
                                    </div>
                                </div>
                                <div className="ms-auto text-end">
                                    <a href="#" className="btn btn-sm px-3 py-1 btn-neutral">Follow</a>
                                </div>
                            </div>

                            <div className="d-flex align-items-center">
                                <div className="me-3">
                                    <img alt="..." className="avatar rounded-circle"
                                        src="/adminkit/img/people/img-3.jpg" />
                                </div>
                                <div className="flex-1">
                                    <a href="#"
                                        className="d-block font-semibold text-sm text-heading text-primary-hover">Kristin
                                        Watson</a>
                                    <div className="text-xs text-muted line-clamp-1">
                                        Web Developer
                                    </div>
                                </div>
                                <div className="ms-auto text-end">
                                    <a href="#" className="btn btn-sm px-3 py-1 btn-neutral">Follow</a>
                                </div>
                            </div>

                            <div className="d-flex align-items-center">
                                <div className="me-3">
                                    <img alt="..." className="avatar rounded-circle"
                                        src="/adminkit/img/people/img-4.jpg" />
                                </div>
                                <div className="flex-1">
                                    <a href="#"
                                        className="d-block font-semibold text-sm text-heading text-primary-hover">Cody
                                        Fisher</a>
                                    <div className="text-xs text-muted line-clamp-1">
                                        Web Developer
                                    </div>
                                </div>
                                <div className="ms-auto text-end">
                                    <a href="#" className="btn btn-sm px-3 py-1 btn-neutral">Follow</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

</main>
        </>

  )
}

export default Profile