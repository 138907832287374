import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

const ViewAllAccountHolder = () => {

    
  return (
    // <Fragment>
    //   <div className="container-fluid vstack gap-3 mt-5">
    //         <div className="card">
         
    //         <div className="col-xl-12">

    //           <div className="card">
    //             <div className="d-flex card-header border-bottom">
    //               <h5 className="mb-0">All Account Holder</h5>
    //               <div className="ms-auto text-end">
    //                   {/* <a href="#" className="text-sm font-semibold">See all</a> */}
    //                 </div>
    //             </div>
                
    //             <div className="table-responsive">
    //               <table className="table table-hover table-nowrap">
    //                 <thead className="table-light">
    //                   <tr>
    //                     <th scope="col">Name</th>
    //                     <th scope="col">Created Date</th>
    //                     <th scope="col">Status</th>
    //                     <th></th>
    //                   </tr>
    //                 </thead>
    //                 <tbody>
    //                   <tr>
    //                     <td>
    //                       <img alt="..." src="/Adminkit/img/people/img-1.jpg" className="avatar avatar-sm rounded-circle me-2" />
    //                       <a className="text-heading font-semibold" href="#">Kristin Watson</a>
    //                     </td>
    //                     <td>
    //                       23-01-2022
    //                     </td>
    //                     <td>
    //                       <span className="badge badge-lg badge-dot">
    //                         <i className="bg-success"></i>Active
    //                       </span>
    //                     </td>
    //                     <td className="text-end">
    //                     <a
    //                       href="#modalholder"
    //                       className="btn btn-sm btn-primary"
    //                       data-bs-toggle="modal"> View
    //                     </a>
    //                     </td>
    //                   </tr>
    //                   <tr>
    //                     <td>
    //                       <img alt="..." src="/Adminkit/img/people/img-2.jpg" className="avatar avatar-sm rounded-circle me-2" />
    //                       <a className="text-heading font-semibold" href="#">Robert Fox</a>
    //                     </td>
    //                     <td>
    //                       23-01-2022
    //                     </td>
    //                     <td>
    //                       <span className="badge badge-lg badge-dot">
    //                         <i className="bg-danger"></i>Deactive
    //                       </span>
    //                     </td>
    //                     <td className="text-end">
    //                     <a
    //                       href="#modalholder"
    //                       className="btn btn-sm btn-primary"
    //                       data-bs-toggle="modal"> View
    //                     </a>
    //                     </td>
    //                   </tr>
    //                   <tr>
    //                     <td>
    //                       <img alt="..." src="/Adminkit/img/people/img-3.jpg" className="avatar avatar-sm rounded-circle me-2" />
    //                       <a className="text-heading font-semibold" href="#">Cody Fisher</a>
    //                     </td>
    //                     <td>
    //                       23-01-2022
    //                     </td>
    //                     <td>
    //                       <span className="badge badge-lg badge-dot">
    //                         <i className="bg-danger"></i>Deactive
    //                       </span>
    //                     </td>
    //                     <td className="text-end">
    //                     <a
    //                       href="#modalholder"
    //                       className="btn btn-sm btn-primary"
    //                       data-bs-toggle="modal"> View
    //                     </a>
    //                     </td>
    //                   </tr>
    //                   <tr>
    //                     <td>
    //                       <img alt="..." src="/Adminkit/img/people/img-4.jpg" className="avatar avatar-sm rounded-circle me-2" />
    //                       <a className="text-heading font-semibold" href="#">Theresa Webb</a>
    //                     </td>
    //                     <td>
    //                       23-01-2022
    //                     </td>
    //                     <td>
    //                       <span className="badge badge-lg badge-dot">
    //                         <i className="bg-success"></i>Active
    //                       </span>
    //                     </td>
    //                     <td className="text-end">
    //                     <a
    //                       href="#modalholder"
    //                       className="btn btn-sm btn-primary"
    //                       data-bs-toggle="modal"> View
    //                     </a>
    //                     </td>
    //                   </tr>
    //                   <tr>
    //                     <td>
    //                       <img alt="..." src="/Adminkit/img/people/img-4.jpg" className="avatar avatar-sm rounded-circle me-2" />
    //                       <a className="text-heading font-semibold" href="#">Theresa Webb</a>
    //                     </td>
    //                     <td>
    //                       23-01-2022
    //                     </td>
    //                     <td>
    //                       <span className="badge badge-lg badge-dot">
    //                         <i className="bg-success"></i>Active
    //                       </span>
    //                     </td>
    //                     <td className="text-end">
    //                     <a
    //                       href="#modalholder"
    //                       className="btn btn-sm btn-primary"
    //                       data-bs-toggle="modal"> View
    //                     </a>
    //                     </td>
    //                   </tr>
                     
    //                   {/* <div className="ms-auto">
    //                   <a href="#" className="text font-semibold">See all</a>
    //                 </div> */}
                      
    //                 </tbody>
    //               </table>

    //             </div>
    //           </div>

    //         </div>
            
          
    //       </div>
    //       </div>
    //        {/* {modal View} */}
    //     <div
    //       className="modal fade"
    //       id="modalholder"
    //       tabIndex={-1}
    //       aria-labelledby="modalholder"
    //       aria-hidden="true"
    //     >
    //       <div className="modal-dialog modal-lg modal-dialog-centered">
    //         <div className="modal-content shadow-3">
    //           <div className="modal-header mb-5">
    //             <div className="h3 text-center">Account Holder Info
                
    //              </div>
    //             <button className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    //           </div>
    //           <div className="modal-body">
    //             {/* Text */}
    //             <div className="container-fluid max-w-screen-md vstack gap-5 p-5 ">
    //               <div className="row">
    //                 <div className='col-sm-4'>
    //                   <div className="text-center">
    //                     <a
    //                       href="#"
    //                       className="avatar w-48 h-48 border border-body border-4 rounded shadow position-relative"
    //                     >
    //                       <img
    //                         alt="..."
    //                         src="/Adminkit/img/people/img-profile.jpg"
    //                         className="rounded"
    //                       />
    //                     </a>
    //                   </div>
    //                 </div>

    //                 <div className='col-sm-8 mx-auto font-bolder'>
    //                   <div className="row">
    //                     <div className="col-sm-6">
    //                       <div className="pb-2">Account Holder Name</div>
    //                       <div className="pb-2">Date</div>
    //                       <div className="pb-2">Plan Name</div>
    //                       <div className="pb-2">Price</div>
    //                       <div className="pb-2">Status</div>
    //                     </div>
    //                     <div className="col-sm-6 text-primary">
    //                       <div className="pb-2">Alex</div>
    //                       <div className="pb-2">12/2/20</div>
    //                       <div className="pb-2">STANDARD</div>
    //                       <div className="pb-2">&euro;100</div>
    //                       <div className="text-success">Active</div>
    //                     </div>
    //                   </div>
    //                   <div className='w-100'>
                        
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
               
    // </Fragment>
    <>
      <main className="py-6 bg-surface-secondary px-6">
                <>
                    {/* {modal} */}
                    <div
                        className="modal fade"
                        id="modalExport"
                        tabIndex={-1}
                        aria-labelledby="modalExport"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content shadow-3">
                            <div className="modal-header mb-5">
                                <div className="h3 text-center"> Account Owner Info</div>
                                <button className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                                <div className="modal-body">
                                    {/* Text */}
                                    <div className="container-fluid max-w-screen-md vstack gap-5 p-5 ">
                                       <div className="row">
                                       <div className='col-sm-4'>
                                            <div className="text-center">
                                                <a
                                                    href="#"
                                                    className="avatar w-48 h-48 border border-body border-4 rounded shadow position-relative"
                                                >
                                                    <img
                                                        alt="..."
                                                        src="/adminkit/img/people/img-profile.jpg" 
                                                        className="rounded"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                       
                                            <div className='col-sm-8 mx-auto font-bolder'>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="pb-2">Account Owner Name</div>
                                                        <div className="pb-2">Date</div>
                                                        <div className="pb-2">Plan Name</div>
                                                        <div className="pb-2">Price</div>
                                                        <div className="pb-2">Status</div>
                                                    </div>
                                                    <div className="col-sm-6 text-primary">
                                                        <div className="pb-2">Alex</div>
                                                        <div className="pb-2">12/2/20</div>
                                                        <div className="pb-2">STANDARD</div>
                                                        <div className="pb-2">&euro;100</div>
                                                        <div className="text-success">Active</div>
                                                    </div>
                                                </div>
                                            </div>
                                       </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid max-w-screen d-flex justify-content-evenly row mx-auto mb-5">
                        <div className="card card_latest_account">
                            <div className="card-header border-bottom">
                                <h5 className="mb-0">Subscribed Users</h5>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-hover table-nowrap">
                                    <thead className="table-light">
                                        <tr>
                                            <th scope="col">Sr No.</th>
                                            <th scope="col">Account Holder Name</th>
                                            <th scope="col">Date of Subscription</th>
                                            <th scope="col">Plan name</th>
                                            <th scope="col">Price</th>
                                            <th scope="col ">Status</th>
                                            <th scope="col last" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>
                                                <img
                                                    alt="..."
                                                    src="/adminkit/img/logos/adminuser.png"
                                                    className="avatar avatar-sm rounded-circle me-2"
                                                />
                                                <a className="text-heading font-semibold" href="#">
                                                    Alexa
                                                </a>
                                            </td>
                                            <td>
                                                <div className="avatar-group">
                                                    <a className="text-heading font-semibold" href="#">
                                                        23-01-2022
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <a className="text-heading font-semibold" href="#">
                                                    Standard
                                                </a>
                                            </td>
                                            <td>
                                                <a className="text-heading font-semibold" href="#">
                                                    &euro;100
                                                </a>
                                            </td>
                                            <td>
                                                <span className="badge badge-lg badge-dot">
                                                    <i className="bg-success" />
                                                    Active
                                                </span>
                                            </td>
                                            <td className="text-end">
                                            <a
                                                    href="#modalExport"
                                                    className="btn btn-sm btn-primary border-base"
                                                    data-bs-toggle="modal"
                                                >
                                                    <span>View</span>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>
                                                <img
                                                    alt="..."
                                                    src="/adminkit/img/logos/adminuser.png"
                                                    className="avatar avatar-sm rounded-circle me-2"
                                                />
                                                <a className="text-heading font-semibold" href="#">
                                                    Alexa
                                                </a>
                                            </td>
                                            <td>
                                                <div className="avatar-group">
                                                    <a className="text-heading font-semibold" href="#">
                                                        23-01-2022
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <a className="text-heading font-semibold" href="#">
                                                    Standard
                                                </a>
                                            </td>
                                            <td>
                                                <a className="text-heading font-semibold" href="#">
                                                    &euro;100
                                                </a>
                                            </td>
                                            <td>
                                                <span className="badge badge-lg badge-dot">
                                                    <i className="bg-danger" />
                                                    Inactive
                                                </span>
                                            </td>
                                            <td className="text-end">
                                            <a
                                                    href="#modalExport"
                                                    className="btn btn-sm btn-primary border-base"
                                                    data-bs-toggle="modal"
                                                >
                                                    <span>View</span>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>
                                                <img
                                                    alt="..."
                                                    src="/adminkit/img/logos/adminuser.png"
                                                    className="avatar avatar-sm rounded-circle me-2"
                                                />
                                                <a className="text-heading font-semibold" href="#">
                                                    Alexa
                                                </a>
                                            </td>
                                            <td>
                                                <div className="avatar-group">
                                                    <a className="text-heading font-semibold" href="#">
                                                        23-01-2022
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <a className="text-heading font-semibold" href="#">
                                                    Standard
                                                </a>
                                            </td>
                                            <td>
                                                <a className="text-heading font-semibold" href="#">
                                                    &euro;100
                                                </a>
                                            </td>
                                            <td>
                                                <span className="badge badge-lg badge-dot">
                                                    <i className="bg-success" />
                                                    Active
                                                </span>
                                            </td>
                                            <td className="text-end">
                                            <a
                                                    href="#modalExport"
                                                    className="btn btn-sm btn-primary border-base"
                                                    data-bs-toggle="modal"
                                                >
                                                    <span>View</span>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>
                                                <img
                                                    alt="..."
                                                    src="/adminkit/img/logos/adminuser.png"
                                                    className="avatar avatar-sm rounded-circle me-2"
                                                />
                                                <a className="text-heading font-semibold" href="#">
                                                    Alexa
                                                </a>
                                            </td>
                                            <td>
                                                <div className="avatar-group">
                                                    <a className="text-heading font-semibold" href="#">
                                                        23-01-2022
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <a className="text-heading font-semibold" href="#">
                                                    Standard
                                                </a>
                                            </td>
                                            <td>
                                                <a className="text-heading font-semibold" href="#">
                                                    &euro;100
                                                </a>
                                            </td>
                                            <td>
                                                <span className="badge badge-lg badge-dot">
                                                    <i className="bg-success" />
                                                    Active
                                                </span>
                                            </td>
                                            <td className="text-end">
                                            <a
                                                    href="#modalExport"
                                                    className="btn btn-sm btn-primary border-base"
                                                    data-bs-toggle="modal"
                                                >
                                                    <span>View</span>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>
                                                <img
                                                    alt="..."
                                                    src="/adminkit/img/logos/adminuser.png"
                                                    className="avatar avatar-sm rounded-circle me-2"
                                                />
                                                <a className="text-heading font-semibold" href="#">
                                                    Alexa
                                                </a>
                                            </td>
                                            <td>
                                                <div className="avatar-group">
                                                    <a className="text-heading font-semibold" href="#">
                                                        23-01-2022
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <a className="text-heading font-semibold" href="#">
                                                    Standard
                                                </a>
                                            </td>
                                            <td>
                                                <a className="text-heading font-semibold" href="#">
                                                    &euro;100
                                                </a>
                                            </td>
                                            <td>
                                                <span className="badge badge-lg badge-dot">
                                                    <i className="bg-danger" />
                                                    Inactive
                                                </span>
                                            </td>
                                            <td className="text-end">
                                                <a
                                                    href="#modalExport"
                                                    className="btn btn-sm btn-primary border-base"
                                                    data-bs-toggle="modal"
                                                >
                                                    <span>View</span>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-footer border-0 py-5">
                            <span className="text-muted text-sm">
                                Showing 10 items out of 250 results found
                            </span>
                        </div>
                    </div>

                </>


            </main>
    </>
  )
}

export default ViewAllAccountHolder