import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { addSubscriptionSchema } from "../../Components/Schemas";
import { InputErrorMessage } from "../../Components/common/ErrorMessages";
import { createSubscriptionPlan, getAllPlansData, getPlanByIdApi, updateSubscriptionPlan } from "../../Apis";
import { toast } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import MapAllPlansData from "../../Components/mapAllPlansData/mapAllPlansData";

const Billing = () => {
  const modal = document.getElementById("modalExport");
  const [allPlansData, setAllPlansData] = useState();
  const [productId, setProductId] = useState("")
  const { values, touched, errors, handleChange, handleBlur, handleSubmit, setValues } =
    useFormik({
      initialValues: { name: "", price: "", description: "" },
      validationSchema: addSubscriptionSchema,
      onSubmit: async (values, action) => {
        console.log(values);
        try {
          const response = productId ? await updateSubscriptionPlan({ ...values, id: productId }) : await createSubscriptionPlan(values);
          if (response?.status === 201 || response?.status === 200) {
            const message = response?.data?.message || response.statusText;
            //   console.log(response?.data)
            getFunctionPlansData();
            modal.click();
            toast.success(message);
          }
        } catch (err) {
          console.log(err);
          const message =
            err.response?.data?.message || err.response.statusText;
          toast.error(message);
        }
      },
    });

  const getFunctionPlansData = async () => {
    try {
      const response = await getAllPlansData();
      if (response?.status === 200) {
        console.log(response?.data?.allPlans);
        setAllPlansData(response?.data?.allPlans);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getPlanDetailsById = async (id) => {
    if (!id) return;
    try {
      const res = await getPlanByIdApi(id);
      if (res?.status === 200 || res?.status === 201) {
        let data = res?.data?.plan;
        setValues({
          name: data?.name,
          price: data?.planPriceId?.price,
          description: data?.description,
          validTill: data?.validTill
        })
      }
      console.log(res);
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (productId) {
      getPlanDetailsById(productId);
    }
  }, [productId])
  useEffect(() => {
    getFunctionPlansData();
  }, []);

  return (
    <>
      <main>
        <header>
          <div className="container-fluid">
            <div className="py-4">
              <div className="row align-items-center">
                <div className="col-sm col-12"></div>
                {/* Actions */}
                <div className="col-sm-auto col-12 mt-4 mt-sm-0">
                  {/* <div className="hstack gap-2 justify-content-sm-end">
                                        <a
                                            href="#modalExport"
                                            className="btn btn-sm btn-primary border-base"
                                            data-bs-toggle="modal"
                                        >
                                            <span className="pe-2">
                                                <i className="bi bi-plus-square-dotted" />
                                            </span>
                                            <span>Add Subscription</span>
                                        </a>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* {modal} */}
        <div
          className="modal fade"
          id="modalExport"
          tabIndex={-1}
          aria-labelledby="modalExport"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content shadow-3">
              <div className="modal-header mb-5">
                <div className="h3 text-center">Edit  Product</div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
                .
              </div>
              <div className="modal-body">
                {/* Text */}
                <form className="col-sm-12 px-10" onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="text">
                      Product Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      className={`form-control ${touched?.name && Boolean(errors.name)
                        ? "border-danger"
                        : ""
                        }`}
                      id="text"
                      placeholder="Enter plan name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    <InputErrorMessage
                      error={touched.name && errors.name}
                      marginBottom={-15}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="price">
                      Price
                    </label>
                    <input
                      type="text"
                      name="price"
                      disabled
                      className={`form-control ${touched?.price && Boolean(errors.price)
                        ? "border-danger"
                        : ""
                        }`}
                      id="price"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Enter Price"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.price}
                    />
                    <InputErrorMessage
                      error={touched.price && errors.price}
                      marginBottom={-15}
                    />
                  </div>
                  {/* <div className="mb-3">
                    <label className="form-label" htmlFor="duration">
                      Plan Duration
                    </label>
                    <input
                      type="text"
                      name="validTill"
                      className={`form-control ${
                        touched?.validTill && Boolean(errors.validTill)
                          ? "border-danger"
                          : ""
                      }`}
                      id="duration"
                      placeholder="Enter Duration"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.validTill}
                    />
                    <InputErrorMessage
                      error={touched.validTill && errors.validTill}
                      marginBottom={-15}
                    />
                  </div> */}
                  <div className="mb-3">
                    <label className="form-label" htmlFor="date">
                      Description
                    </label>
                    <textarea
                      type="string"
                      name="description"
                      className={`form-control ${touched?.description && Boolean(errors.description)
                        ? "border-danger"
                        : ""
                        }`}
                      id="date"
                      placeholder="Enter title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                    >
                      {" "}
                    </textarea>
                    <InputErrorMessage
                      error={touched.description && errors.description}
                      marginBottom={-15}
                    />
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="btn btn-primary w-full my-6"
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* {container table} */}
        <div className="container-fluid">
          {/* Filters */}
          {/* Table */}
          <div className="card">
            <div className="card-header border-bottom d-flex align-items-center">
              <h3 className="me-auto">Existing Products</h3>
            </div>
            <div className="table-responsive">
              <table className="table table-hover table-nowrap">
                <thead className="table-light">
                  <tr>
                    <th scope="col">Product ID</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                    {/* <th scope="col">Duration</th> */}
                    <th scope="col">Date Created</th>
                    <th className="text-end" style={{paddingRight:"40px"}} scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allPlansData?.map((item, index) => {
                    return (
                      <tr>
                        <td>
                          <span className="text-heading font-bold">
                            {item?._id}
                          </span>
                        </td>
                        <td>
                          <img
                            alt="..."
                            src="/adminkit/img/logos/adminuser.png"
                            className="avatar avatar-sm rounded-circle me-2"
                          />
                          <a className="text-heading font-semibold">
                            {item?.name}
                          </a>
                        </td>
                        <td>
                          <span className="text-heading font-bold">
                            {" "}
                            € {item?.planPriceId?.price}
                          </span>
                        </td>
                        {/* <td>
                            <span className="text-heading font-bold">
                              {item.validTill}
                            </span>
                          </td> */}
                        <td>
                          <span className="text-heading font-bold">
                            {moment(item?.createdAt.split("T")[0]).format(
                              "DD/MM/YYYY"
                            )}
                          </span>
                        </td>
                        <td className="text-end">
                          <Link
                            className="btn btn-primary btn-sm  "
                            to="/proudctView"
                            state={{ id: item?._id }}
                          >
                            View
                          </Link>
                        </td>
                        {/* <td>
                            <button
                              className="btn btn-primary "
                              data-bs-toggle="modal"
                              data-bs-target="#modalExport"
                              onClick={()=>setProductId(item?._id)}
                            >
                              Edit
                            </button>
                          </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {/* <div className="card-footer border-0 py-5">
              {/* <span className="text-muted text-sm">
                                    Showing 10 items out of 250 results found
                                </span> */}
            {/* </div> */}
          </div>

        </div>
      </main>
    </>
  );
};
export default Billing;
