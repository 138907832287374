import React, { useContext, useEffect, useState } from "react";
import { editProfileSchema } from "../../../Components/Schemas";
import { useFormik } from "formik";
import { updateAccountOwnerProfileApi, updateAdminProfileApi } from "../../../Apis";
import { toast } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import { InputErrorMessage } from "../../../Components/common/ErrorMessages";
import { AdminProfileApi, imageProfileApi } from "../../../Apis";
import AuthContext from "../../../Components/context/AuthProvider";
import { SERVER_ENDPOINT } from "../../../utils/baseurl";
import img from '../../../Assets/image/img-profile.jpg';
const EditProfile = () => {

  const [image, setImage] = useState("");
  const [profile, setProfile] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const appContext = useContext(AuthContext);
  const { fetchUserDetails, state } = appContext;
  const { loggedInUser } = state;

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    handleChange,
    setErrors,
    setFieldValue,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      contactNo: "",
      profilePic: "",
      salutation: "",
      email: "",
    },
    validationSchema: editProfileSchema,
    onSubmit: async (values) => {
      try {
        // const { name, contactNo } = values;
        // const firstName = name.split(" ")[0];
        // const lastName = name.split(" ")[1] || "";
        // const value = { salutation,  firstName, lastName, contactNo };
        // console.log(values)
        const response = await updateAdminProfileApi(values);
        if (response?.status === 200 || response?.status === 201) {
          // console.log(response?.data?.user)
          // localStorage.setItem("adminData", JSON.stringify(value))
          fetchUserDetails();
          toast.success("Profile Updated Successfully!");
        } else {
          toast.error("Something went wrong.");
        }
      } catch (err) {
        console.log(err);
        const message = err.response?.data?.message || err.response.statusText;
        toast.error(message);
        setIsLoading(false);
      }
    },
  });

  console.log(state,"SADFTEASFSDASDF")
  const handleFileChange = async (e) => {
    console.log(e,"SADFSDAFASDFSDATET");
    try {
      let file = e.target.files[0];
      console.log(file, "fileeeeeeeeeeeeeeeeeeeeeeeeeeeee");

      if (!file) {
        return;
      }
      const type = file.type.split("/")[1];
      console.log(type, "type");
      const imageType = ["jpeg", "jpg", "png"];
      const validImageType = imageType.includes(type);
      if (!validImageType) {
        return toast.error("Upload a valid image : jpeg, png, jpg");
        //  setErrors({ profilePic: "upload a valid image : jpeg, png, jpg" });
        //  return;
      } else if (file.size > 10000001) {
        return toast.error("Image size cannot be more than 10 MB");
        // setErrors({profilePic: "image size cannot be more than 10 MB"})
        // setProfile("");
        //  return;
      } else {
        let fr = new FileReader();

        fr.readAsDataURL(file);
        fr.onload = function () {
          setProfile(fr.result);
        };
        // console.log(fr.result);
        //  console.log(profile)

        setErrors({});
      }

      const formdata = new FormData();
      formdata.append("file", file);
      const response = await imageProfileApi(formdata);
      toast.success(response?.data?.message);
      setFieldValue("profilePic",response?.data?.filePath)
      fetchUserDetails();
    } catch (err) {
      const message = err.response?.data?.message || err.response.statusText;
      toast.error(message);
    }
  };
  const handleRemove = async () => {
    try {
      const response = await updateAccountOwnerProfileApi({ profilePic: "" });
      setFieldValue("profilePic", "");
      if (response.status === 200) {
        fetchUserDetails();
        toast.success("Profile picture removed successfully!");
      } else {
        toast.error("Something went wrong.");
      }
    } catch (err) {
      const message = err.response?.data?.message || err.response.statusText;
      toast.error(message);
    }
    fetchUserDetails();
  };

  console.log(loggedInUser,"SDAFIADSFVCARUSUTA");
  useEffect(() => {
    if (loggedInUser) {
      const { firstName, lastName, contactNo, email, profilePic, salutation } =
        loggedInUser;
      console.log(loggedInUser, "loggedInUser");
      setFieldValue("salutation", salutation);
      setFieldValue("firstName", firstName);
      setFieldValue("lastName", lastName);
      setFieldValue("contactNo", contactNo);
      setFieldValue("email", email);
      setFieldValue("profilePic", profilePic);
    }
  }, []);

  return (
    <div className="container-fluid">
      <div className="row mt-6">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <h3 className="font-bolder h3 mb-5">Edit Super Admin Profile</h3>
                <hr className="mb-10" />
                <div className="col-md-4 col-sm-4">
                  <h3 className="h4 mb-5 text-center img-head">
                    Upload Image
                  </h3>
                  <div className="d-flex flex-column align-items-center text-center">
                    <a className="avatar w-48 h-48 border border-body border-4 rounded shadow position-relative">
                      <div
                        className="border position-absolute rounded text-center bg-white bottom-0 end-0"
                        style={{
                          height: "2em",
                          width: "2em",
                          cursor: "pointer",
                        }}
                      >
                        <label htmlFor="image">
                          <input
                            name="profilePic"
                            id="image"
                            type="file"
                            style={{ display: "none" }}
                            accept="image/png, image/jpeg, image/jpg"
                            hidden={true}
                            // value={values?.profilePic ? values?.profilePic:""}
                            onChange={handleFileChange}
                          />
                          <div>
                          <i className="bi bi-camera text-black"></i>
             
                          </div>
                         
                        </label>
                        {/* <input type="file"/> */}
                        <a
                            className="edit-delete-profile text-danger-hover border position-absolute rounded text-center bg-white bottom-0"
                            style={{
                              height: "2em",
                              width: "2em",
                              cursor: "pointer",
                              left: "-38px",
                            }}
                            title="Delete Image"
                            onClick={handleRemove}
                          >
                            <i className="bi bi-trash" aria-hidden="true"></i>
                          </a>
                      </div>
                      {console.log(`${SERVER_ENDPOINT}/${values?.profilePic}`, 'values.profilePic')}
                      <img
                        alt="..."
                        src={`${SERVER_ENDPOINT}/${values?.profilePic}`}
                        onError={(event) => {
                          event.target.src = img;
                          event.onerror = null;
                        }}
                        className="rounded"
                      />
                      {console.log(values?.profilePic , "profilefpifd")}
                    </a>
                  </div>
                </div>

                <div className="col-md-8 col-sm-8">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="mb-5 col-lg-2">
                        <label className="form-label" htmlFor="name">
                          Honorific
                        </label>
                        <select
                          type="string"
                          name="salutation"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.salutation}
                          className={`form-select px-2  form-control-solid ${touched.salutation && Boolean(errors.salutation)
                            ? "border-danger"
                            : ""
                            }`}
                        >
                          <option value="Dr.">Dr.</option>
                          <option value="Mr.">Mr.</option>
                          <option value="Ms.">Ms.</option>
                        </select>
                        <InputErrorMessage
                          error={touched.salutation && errors.salutation}
                          marginBottom={-15}
                        />
                      </div>

                      <div className="mb-5 col-lg-5">
                        <label className="form-label" htmlFor="name">
                          First Name
                        </label>
                        <input
                          type="text"
                          name="firstName"
                          id="name"
                          placeholder="Enter Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.firstName}
                          className={`form-control form-control-solid ${touched.firstName && Boolean(errors.firstName)
                            ? "border-danger"
                            : ""
                            }`}
                        />
                        {/* {console.log(Boolean(errors.email))} */}
                        <InputErrorMessage
                          error={touched.firstName && errors.firstName}
                          marginBottom={-15}
                        />
                      </div>
                      <div className="mb-5 col-lg-5">
                        <label className="form-label" htmlFor="name">
                          Last Name
                        </label>
                        <input
                          type="text"
                          name="lastName"
                          id="name"
                          placeholder="Enter Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.lastName}
                          className={`form-control form-control-solid ${touched.lastName && Boolean(errors.lastName)
                            ? "border-danger"
                            : ""
                            }`}
                        />
                        {/* {console.log(Boolean(errors.email))} */}
                        <InputErrorMessage
                          error={touched.lastName && errors.lastName}
                          marginBottom={-15}
                        />
                      </div>
                      <div className="mb-5 col-lg-6">
                          <label className="form-label" htmlFor="name">
                            Contact No
                          </label>
                          <input
                            type="text"
                            name="contactNo"
                            id="name"
                            placeholder="Enter Contact Number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.contactNo}
                            className={`form-control form-control-solid ${touched.contactNo && Boolean(errors.contactNo)
                              ? "border-danger"
                              : ""
                              }`}
                          />
                          <InputErrorMessage
                            error={touched.contactNo && errors.contactNo}
                            marginBottom={-15}
                          />
                        </div>

                        <div className="mb-5 col-lg-6">
                          <label className="form-label" htmlFor="email">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            value={values?.email}
                            id="email"
                            disabled
                          />
                        </div>
                      </div>

                    <div className="text-end mt-2 mb-2">
                      <button
                        type="submit"
                        className="btn btn-primary"
                      >
                        Update
                      </button>
                    </div>
                    {/* <ToastContainer autoClose={2000}/> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
