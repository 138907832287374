import React, { useEffect, useState } from "react";
import MainRouter from "./MainRouter/MainRouter";
import AuthContext from "./Components/context/AuthProvider";
import { isAuth, removeAdmin, removeToken } from "./utils/helperFunctions";
import { AdminProfileApi } from "./Apis";
import {Toaster} from 'react-hot-toast'

function App() {
  
  const [isLoggedIn, setIsLoggedIn] = useState(false); // false boolean
  const [loggedInUser, setLoggedInUser] = useState(null)





const fetchUserDetails = async () =>{
  try {
    const response = await AdminProfileApi() ;
    if(response?.status === 200 || response?.status=== 201){
      setLoggedInUser(response?.data?.user)
      console.log(response?.data?.user,"USERESFDASDUF")
      setIsLoggedIn(true) 
    }
    
  } catch(err) {
    console.log(err)
  }

}
  const logout = () => {
    removeToken()
    setIsLoggedIn(false)
    setLoggedInUser({
      loading: false,
      data: null,
    });
    removeAdmin();
  }

  useEffect(()=>{
    fetchUserDetails()
  },[])
 
  return (
    <div className="App">
      <AuthContext.Provider 
       value={{
        state: {
          isLoggedIn,
          loggedInUser
        },  
        fetchUserDetails,
        logout
      }}
      >
        <MainRouter/>
      </AuthContext.Provider>
      <Toaster autoClose={2000}/>
    </div>
  );
  
}

export default App;
