import axios from "axios";
import { API_BASE_URL } from "../utils/baseurl";

export const postApiWithoutToken = (path, data) => {
    return axios.post(API_BASE_URL + path, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
}



export const patchApiWithoutToken = (path, data) => {
  return axios.patch(API_BASE_URL + path, data, {
    headers: {
      "Content-Type": "application/json",
    },
  })
}


export const patchPasswordApi = (path, data) => {
  const token = localStorage.getItem("token")
  return axios.patch(API_BASE_URL + path, data, {
    headers: {
      "Content-Type": "application/json",
     Authorization: `Bearer ${token}`
    }
  })
}

export const patchAdminProfileApi = (path, data) => {
  const token = localStorage.getItem("token")
  return axios.patch(API_BASE_URL + path, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  })
}


export const getAdminProfileApi = (path) => {
  const token = localStorage.getItem("token")
  return axios.get(API_BASE_URL + path, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  })
}



export const updateImageProfileApi = (path, data) => {
  const token = localStorage.getItem("token")
  return axios.post(API_BASE_URL + path,  data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  })
}

export const postSubscriptionPlanApi = (path, data) => {
  const token = localStorage.getItem("token")
  return axios.post(API_BASE_URL + path,  data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  })
}

export const getAllSubscriptionPlansData = (path) => {
  const token = localStorage.getItem("token")
  return axios.get(API_BASE_URL + path, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  })
}


export const getApi = (path) => {
  const token = localStorage.getItem("token");
  if(!token) return ;
  return axios.get(API_BASE_URL + path, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  })
}
 

export const patchApi = (path, data) => {
  const token = localStorage.getItem("token")
  if(!token) return ;
  return axios.patch(API_BASE_URL + path, data, {
    headers: {
      "Content-Type": "application/json",
     Authorization: `Bearer ${token}`
    }
  })
}