import React, { useContext, useState } from "react";
import Header from "./Header";
import AuthContext from "../context/AuthProvider";
import { Link, useLocation } from "react-router-dom";
import { SERVER_ENDPOINT } from "../../utils/baseurl";
import img from '../../Assets/image/img-profile.jpg';
const Navbar = ({ pageData }) => {
  const location = useLocation();
  const appContext = useContext(AuthContext);
  const { isLoggedIn, loggedInUser } = appContext?.state;
  const { logout } = appContext;

  const getTitle = () => {
    return pageData.find((l) => l?.pathname === location?.pathname)?.name || "";
  };

  return (
    <>
      <nav
        className="navbar navbar-light position-lg-sticky top-lg-0 d-none d-lg-block overlap-10 flex-none bg-white border-bottom px-0 py-3"
        id="topbar"
      >
        <div className="container-fluid justify-content-between">
          <div className="navbar-user page-title">{getTitle()}</div>
          {/* <!-- Form --> */}

          {/* <!-- Right menu --> */}
          <div className="navbar-user d-none d-sm-block">
            <div className="hstack gap-3 ms-4">
              <div className="dropdown ">
                <a
                  className="d-flex align-items-center"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <div>
                    <div className="avatar avatar-sm bg-warning rounded-circle text-white">
                      <img
                         alt="..."
                         src={`${SERVER_ENDPOINT}/${loggedInUser?.profilePic}`}
                         onError={(event) => {
                           event.target.src = img;
                           event.onerror = null;
                         }}
                      />
                    </div>
                  </div>
                  <div className="d-none d-sm-block ms-3">
                    {Boolean(loggedInUser?.salutation) ? (
                      <span className="h6">
                        {loggedInUser?.salutation +
                          " " +
                          loggedInUser?.firstName +
                          " " +
                          loggedInUser?.lastName}
                      </span>
                    ) : (
                      <span className="h6"></span>
                    )}
                  </div>
                  <div className="d-none d-md-block ms-md-2">
                    <i className="bi bi-chevron-down text-muted text-xs"></i>
                  </div>
                </a>
                <div className="dropdown-menu dropdown-menu-end">
                  <div className="dropdown-header">
                    <span className="badge badge-lg badge-dot">
                      <i className="bg-success"></i>
                      {Boolean(loggedInUser?.salutation) ? (
                        <span className="d-block text-heading font-semibold">
                          {loggedInUser?.salutation +
                            " " +
                            loggedInUser?.firstName +
                            " " +
                            loggedInUser?.lastName}
                        </span>
                      ) : (
                        <span className="d-block text-heading font-semibold"></span>
                      )}
                    </span>
                  </div>
                  <Link className="dropdown-item" to="editprofile">
                    <i className="fa-solid fa-user-pen me-3 text-muted"></i>Edit
                    profile
                  </Link>
                  <Link className="dropdown-item" to="updatepassword">
                    <i className="fa-solid fa-lock me-3 text-muted"></i>Change
                    Password
                  </Link>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item" href="#" onClick={logout}>
                    <i className="fa-solid fa-right-from-bracket me-3 text-muted"></i>
                    Logout
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* <Header/> */}
    </>
  );
};

export default Navbar;
