import { validateYupSchema } from "formik";
import React from "react";
import { useState } from "react";
import DatePicker from "react-date-picker";
import { downloadAccountOwners, downloadPateints, downloadProviders } from "../../Apis";
import { API_BASE_URL } from "../../utils/baseurl";
import { saveAs } from "file-saver";
import { toast } from "react-hot-toast";
import { WhiteLoader } from "../../Components/common/loader/WhiteLoader";
const Report = () => {

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [data,setData]=useState("pateint");
  const [loader,setLoader]=useState(false)
  const [dates,setDates]=useState({
    startDate:new Date().toLocaleDateString()?.split("/").reverse().join("-"),
    endDate:new Date().toLocaleDateString()?.split("/").reverse().join("-")
  })
  const handleSelcet =async(e)=>{
    console.log(e.target.value,"target fdkjfads")
    setData(e.target.value);
  }


  const handleDownload =async()=>{
    if(!data && !dates.startDate && !dates.endDate) return ;
    setLoader(true)
    const token = localStorage.getItem("token")
    if(data === "owner"){
      // const res = await downloadAccountOwners();
      // console.log(res,"respoinsedsf")
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      fetch(`${API_BASE_URL}/admin/exports/accountOwners?startDate=${dates.startDate}&endDate=${dates.endDate}`,{
        headers:headers
      })
      .then((response) => response.blob())
      .then((blob) => {
        // Save the blob as a file using FileSaver
        saveAs(blob, "data.xlsx");
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
        toast.error("Error downloading the CSV file")
        console.error("Error downloading the CSV file:", error);
      });
    }else if( data === "pateint"){
      // const res = await downloadPateints();
      // console.log(res)
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      fetch(`${API_BASE_URL}/admin/exports/patients?startDate=${dates.startDate}&endDate=${dates.endDate}`,{
        headers:headers
      })
      .then((response) => response.blob())
      .then((blob) => {
        // Save the blob as a file using FileSaver
        saveAs(blob, "data.xlsx");
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
        toast.error("Error downloading the CSV file")
        console.error("Error downloading the CSV file:", error);
      });
    }else{
      // const res =await downloadProviders();
      // console.log(res)
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      fetch(`${API_BASE_URL}/admin/exports/providers?startDate=${dates.startDate}&endDate=${dates.endDate}`,{
        headers:headers
      })
      .then((response) => response.blob())
      .then((blob) => {
        // Save the blob as a file using FileSaver
        saveAs(blob, "data.xlsx");
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
        toast.error("Error downloading the CSV file")
        console.error("Error downloading the CSV file:", error);
      });
    }
  }

  const handleStartDate =(e)=>{
    setStartDate(e)
    setDates({...dates,startDate:e.toLocaleDateString().split("/").reverse().join("-")})
  }
  const handleEndDate =(e)=>{
    setEndDate(e)
    setDates({...dates,endDate:e.toLocaleDateString().split("/").reverse().join("-")})
  }
  return (

    <main className="py-2">
      {/* <!-- Container --> */}

      <div className="container-fluid">
        <div className="row mt-6">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md">
                    <div>
                      <label className="form-label">From date</label>
                      <div className="input-group input-group-inline">
                        <DatePicker selected={startDate} onChange={handleStartDate} value={startDate} min={1000} max={9999} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md">
                    <div>
                      <label className="form-label">To date</label>
                      <div className="input-group input-group-inline">
                        <DatePicker selected={startDate} onChange={handleEndDate} value={endDate} min={1000} max={9999} />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm">
                    <div>
                      <label className="form-label">Client</label>
                      <select onChange={handleSelcet} value={data} className="form-select">
                        <option value="pateint"> Patients</option>
                        <option value="provider">Health Providers</option>
                        <option value="owner">Account Owners</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-auto align-self-end">
                    <button type="button" className="btn btn-primary">
                      <span className=" d-sm-inline" onClick={handleDownload}> {loader ? <WhiteLoader/> : <><i className="bi bi-download"></i> Download </>} </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Report;
