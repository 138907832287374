import React, { useEffect, useState, useContext } from "react"
import { useFormik } from "formik"
import {toast} from 'react-hot-toast'
import "react-toastify/dist/ReactToastify.css"
import { loginSchema } from "../../../Components/Schemas"
import { loginApi, forgotPasswordApi } from "../../../Apis"
import { setToken } from "../../../utils/helperFunctions"
import { useNavigate } from "react-router-dom"
import AuthContext from "../../../Components/context/AuthProvider"
import { InputErrorMessage } from "../../../Components/common/ErrorMessages"
import { WhiteLoader } from "../../../Components/common/loader/WhiteLoader"

const initialValues = {
  email: "",
  password: "",
  acceptCheckbox: true,
}

const Login = () => {
  const [toggle, setToggle] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)
  const appContext = useContext(AuthContext)
  const { fetchUserDetails } = appContext
  const { isLoggedIn } = appContext.state
  const [isloading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const [loader,setLoader]=useState(false)
  const togglePassword = () => {
    setPasswordShown(!passwordShown)
  }

  const { values, errors,setFieldValue, touched, handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, action) => {
      setLoader(true)
      try {
       
          const response = await loginApi(values)
          if (response?.status === 200 || response?.status === 201) {
            const token = response?.data?.token
            setToken(token)
            fetchUserDetails()
            setLoader(false)
            toast.success(response?.data?.message)
          } else {
            toast.error("Something went wrong.")
          }
        
      } catch (err) {
        const message = err.response?.data?.message || err.response.statusText
        toast.error(message)
        setIsLoading(false)
        setLoader(false)
      }
    },
  })

  // console.log(email, "emailllllllllllllll")
  const handleforgotPassword = async () => {
    if(values?.email){
      try {
        // console.log(email, "emaillllllllllll")
        const response = await forgotPasswordApi({email:values.email})
        console.log(response, "rrrrrrrrrrrrrrrr");
        if(response.status === 200 || response.status === 201){
          toast.success(response?.data?.message);
          setFieldValue("password","")
          console.log(response,"RESPONSE")
        }

    } catch (err) {
       console.log(err)
    }
    }
      
  }

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/")
    }
  }, [isLoggedIn])


  return (
    <>
      <div>
        <div className='px-5 py-5 p-lg-0 min-h-screen bg-surface-secondary d-flex flex-column justify-content-center'>
          <div className='d-flex justify-content-center'>
            <div className='col-lg-5 col-xl-4 p-12 p-xl-20 position-fixed start-0 top-0 h-screen overflow-y-hidden bg-primary d-none d-lg-flex flex-column'>
              {/* <!-- Logo --> */}
              <a className='d-block' href='#'>
                <img src='/adminkit/img/logos/Logo-White.png' className='h-24' alt='...' />
              </a>
              {/* <!-- Title --> */}
              <div className='mt-32 mb-20'>
                <h1 className='ls-tight font-bolder display-6 text-white mb-5'>Welcome to Super Admin</h1>
                <p className='text-white text-opacity-80'>Maybe some text here will help me see it better. Oh God. Okay, let's do it then.</p>
              </div>
              {/* <!-- Circle --> */}
              <div className='w-56 h-56 bg-blue-100 rounded-circle position-absolute bottom-0 end-20 transform translate-y-1/3'></div>
            </div>
            <div className='col-12 col-md-9 col-lg-7 offset-lg-5 border-left-lg min-h-screen d-flex flex-column justify-content-center position-relative'>
              <div className='py-lg-16 px-lg-20'>
                <div className='row'>
                  <div className='col-lg-10 col-md-9 col-xl-6 mx-auto ms-xl-0'>
                    <div className='mt-10 mt-lg-5 mb-6 d-lg-block text-center'>
                      <span className='d-inline-block d-lg-block h-24 mb-4 mb-lg-6 me-3'>
                        <i className='bi bi-person-circle login-icon'></i>
                      </span>
                      <h1 className='ls-tight font-bolder h2'>Super Admin Login</h1>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className='mb-5'>
                        <label className='form-label' htmlFor='email'>
                          Email address
                        </label>
                        <input
                          name='email'
                          type='email'
                          id='email'
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter email"
                          className={`form-control form-control-solid ${touched?.email && Boolean(errors.email) ? "border-danger" : ""}`}
                        />
                        <InputErrorMessage error={touched.email && errors.email} marginBottom={-15} />
                        {/* {errors.email && touched.email ? (
                                       <div className="form-error">{errors.email}</div>
                                    ) : null } */}
                      </div>
                      <div className='mb-5'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <div>
                            <label className='form-label' htmlFor='password'>
                              Password
                            </label>
                          </div>
                          <div className='mb-2'>
                            <button type="button" className='text-sm text-muted text-primary-hover text-underline' onClick={handleforgotPassword}>
                              Get New Password
                            </button>
                          </div>
                        </div>
                        <div style={{ position: "relative" }}>
                          <input
                            name='password'
                            type={passwordShown ? "text" : "password"}
                            id='password'
                            autoComplete='current-password'
                            value={values?.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Enter password"
                            className={`form-control form-control-solid ${touched.password && Boolean(errors.password) ? "border-danger" : ""}`}
                          />
                          <i
                            className={passwordShown ? "bi bi-eye-fill" : "bi bi-eye-slash-fill"}
                            style={{ position: "absolute", right: "10px", top: "10px", cursor: "pointer" }}
                            onClick={togglePassword}></i>
                          {/* <i className="bi bi-eye-slash-fill"></i> */}
                          {/* "bi bi-eye-fill" */}
                          <InputErrorMessage error={touched.password && errors.password} marginBottom={-15} />
                        </div>
                        {/* {errors.password && touched.password ? (
                                       <div className="form-error">{errors.password}</div>
                                    ) : null } */}
                      </div>
                      <div className='mb-5'>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='acceptCheckbox'
                            id='check_example'
                            value={values.acceptCheckbox}
                            onChange={handleChange}
                          />
                          <label className='form-check-label' htmlFor='check_example'>
                            Keep me logged in
                          </label>
                        </div>
                      </div>
                      <div>
                        {/* <Link to="/" className="btn btn-primary w-full"> */}
                        <button type='submit' disabled={loader} className='btn btn-primary w-full' >
                          {loader ? <WhiteLoader/>:"Sign In"} 
                        </button>
                        {/* </Link> */}
                      </div>
                      {/* <ToastContainer autoClose={2000}/> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
