import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getPlanByIdApi, updatePriceApi, updateSubscriptionPlan } from "../../Apis";
import moment from "moment/moment";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { addSubscriptionSchema } from "../../Components/Schemas";
import { InputErrorMessage } from "../../Components/common/ErrorMessages";
const ProductView = () => {
  const location = useLocation();
  const modal = document.getElementById("modalExport1");
  const modal1 = document.getElementById("modalExport");
  const [planData, setPlanData] = useState();
  const [priceHistory, setPriceHistory] = useState();
  const [price, setPrice] = useState({ price: "", id: "" });
  const [productId, setProductId] = useState("")

  const { values, touched, errors, handleChange, handleBlur, handleSubmit, setValues } =
    useFormik({
      initialValues: { name: "", price: "", description: "" },
      validationSchema: addSubscriptionSchema,
      onSubmit: async (values, action) => {
        console.log(values);
        try {
          const response = await updateSubscriptionPlan({ ...values, id: productId })
          if (response?.status === 201 || response?.status === 200) {
            const message = response?.data?.message || response.statusText;
            getDetailsById(response.data.plan._id);
            modal.click();
            toast.success(message);
          }
        } catch (err) {
          console.log(err);
          const message =
            err.response?.data?.message || err.response.statusText;
          toast.error(message);
        }
      },
    });
  const getDetailsById = async (id) => {
    try {
      const res = await getPlanByIdApi(id);
      if (res?.status === 200 || res?.status === 201) {
        setPlanData(res?.data?.plan);
        setPriceHistory(res?.data?.planPriceHistory);
      }
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePrice = (id) => {
    const newData = priceHistory?.filter((item) => item._id === id);
    setPrice({ ...price, price: "", id: newData[0]._id });
  };

  const getPlanDetailsById = async (id) => {
    if (!id) return;
    try {
      const res = await getPlanByIdApi(id);
      if (res?.status === 200 || res?.status === 201) {
        let data = res?.data?.plan;
        setValues({
          name: data.name,
          price: data.planPriceId.price,
          description: data.description,
          validTill: data.validTill
        })
      }
      console.log(res);
    } catch (err) {
      console.log(err)
    }
  }

  console.log(price, "SDFDSFSD")
  const handlePriceInfo = async () => {
    if (planData && price.price) {
      const res = await updatePriceApi({ id: planData?._id, price: price.price });
      if (res?.status === 200 || res?.status === 201) {
        modal1.click()
        toast.success(res.data.message);
        getDetailsById(res.data.plan._id);
      }
      console.log(res)
    }
  }



  useEffect(() => {
    if (productId) {
      getPlanDetailsById(productId);
    }
  }, [productId])
  useEffect(() => {
    if (location?.state?.id) {
      getDetailsById(location?.state?.id);
    }
  }, [location]);
  return (
    <main className="py-6 bg-surface-secondary px-6">
      <header>
        <div className="container-fluid">
          <div className="py-4">
            <div className="row align-items-center">
              <div className="col-sm col-12"></div>
              {/* Actions */}
              <div className="col-sm-auto col-12 mt-4 mt-sm-0">
                {/* <div className="hstack gap-2 justify-content-sm-end">
                                    <a
                                        href="#modalExport"
                                        className="btn btn-sm btn-primary border-base"
                                        data-bs-toggle="modal"
                                    >
                                        <span className="pe-2">
                                            <i className="bi bi-plus-square-dotted" />
                                        </span>
                                        <span>Add Subscription</span>
                                    </a>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* {container table} */}
      <div className="container-fluid">
        <div className="vstack gap-4">
          {/* Filters */}
          {/* Table */}
          <div className="card">
            <div className="card-header border-bottom d-flex align-items-center">
              <h5 className="me-auto">Existing Products</h5>
            </div>
            <div className="table-responsive">
              <table className="table table-hover table-nowrap">
                <thead className="table-light">
                  <tr>
                    <th scope="col">Product ID</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                    {/* <th scope="col">Duration</th> */}
                    <th scope="col">Date Created</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span className="text-heading font-bold">
                        {planData?._id}
                      </span>
                    </td>
                    <td>
                      <img
                        alt="..."
                        src="/adminkit/img/logos/adminuser.png"
                        className="avatar avatar-sm rounded-circle me-2"
                      />
                      <a className="text-heading font-semibold">
                        {planData?.name}
                      </a>
                    </td>
                    <td>
                      <span className="text-heading font-bold">
                        {" "}
                        € {planData?.planPriceId?.price}
                      </span>
                    </td>
                    {/* <td>
                        <span className="text-heading font-bold">
                          {item.validTill}
                        </span>
                      </td> */}
                    <td>
                      <span className="text-heading font-bold">
                        {moment(planData?.createdAt?.split("T")[0]).format(
                          "DD/MM/YYYY"
                        )}
                      </span>
                    </td>
                    <td>

                      <button
                        className="btn btn-primary btn-sm"
                        style={{ width: "fit-content" }}
                        data-bs-toggle="modal"
                        data-bs-target="#modalExport1"
                        onClick={() => setProductId(planData?._id)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-primary btn-sm"
                        style={{ width: "fit-content" }}
                        data-bs-toggle="modal"
                        data-bs-target="#modalExport"
                        onClick={() => handlePrice(planData?.planPriceId?._id)}
                      >
                        Update Price
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="card">
            <div className="card-header border-bottom d-flex align-items-center">
              {/* <h5 className="me-auto">Existing Products</h5> */}
            </div>
            <div className="table-responsive">
              <table className="table table-hover table-nowrap">
                <thead className="table-light">
                  <tr>
                    <th scope="col">S.No.</th>
                    <th scope="col">Price</th>
                    <th scope="col">Date created</th>
                  </tr>
                </thead>
                <tbody>
                  {Boolean(priceHistory?.length) &&
                    priceHistory?.map((v, i) => {
                      return (
                        <tr>
                          <td>
                            <span className="text-heading font-bold">
                              {i + 1}
                            </span>
                          </td>
                          <td>
                            <span className="text-heading font-bold">
                              {v?.price}
                            </span>
                          </td>
                          <td>
                            <span className="text-heading font-bold">
                              {moment(v?.createdAt?.split("T")[0]).format(
                                "DD/MM/YYYY"
                              )}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="modalExport"
        tabIndex={-1}
        aria-labelledby="modalExport"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content shadow-3">
            <div className="modal-header mb-5">
              <div className="h3 text-center">New Price</div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>

            </div>
            <hr className="mt-0" />
            <div className="modal-body">
              {/* Text */}
              <form className="col-sm-12">
                <div className="mb-3">
                  <label className="form-label" htmlFor="text">
                    Price
                  </label>
                  <input
                    type="text"
                    name="name"
                    className={`form-control`}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    id="text"
                    placeholder="Enter price"
                    value={price?.price}
                    onChange={(e) => setPrice({ ...price, price: e.target.value })}
                  />
                </div>
                <span style={{ color: "red", fontSize: "15px", fontWeight: "bold" }}>Once you add new price . Its eligible for new users !</span>
                <div>
                  <button
                    type="button"
                    onClick={handlePriceInfo}
                    className="btn btn-primary w-full my-4"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="modalExport1"
        tabIndex={-1}
        aria-labelledby="modalExport"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content shadow-3">
            <div className="modal-header mb-5">
              <div className="h3 text-center">Edit  Product</div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>

            </div>
            <hr className="mt-0" />
            <div className="modal-body">
              {/* Text */}
              <form className="col-sm-12" onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label className="form-label" htmlFor="text">
                    Product Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    className={`form-control ${touched?.name && Boolean(errors.name)
                      ? "border-danger"
                      : ""
                      }`}
                    id="text"
                    placeholder="Enter plan name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  <InputErrorMessage
                    error={touched.name && errors.name}
                    marginBottom={-15}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label" htmlFor="price">
                    Price
                  </label>
                  <input
                    type="text"
                    name="price"
                    disabled
                    className={`form-control ${touched?.price && Boolean(errors.price)
                      ? "border-danger"
                      : ""
                      }`}
                    id="price"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="Enter Price"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.price}
                  />
                  <InputErrorMessage
                    error={touched.price && errors.price}
                    marginBottom={-15}
                  />
                </div>
                {/* <div className="mb-3">
                    <label className="form-label" htmlFor="duration">
                      Plan Duration
                    </label>
                    <input
                      type="text"
                      name="validTill"
                      className={`form-control ${
                        touched?.validTill && Boolean(errors.validTill)
                          ? "border-danger"
                          : ""
                      }`}
                      id="duration"
                      placeholder="Enter Duration"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.validTill}
                    />
                    <InputErrorMessage
                      error={touched.validTill && errors.validTill}
                      marginBottom={-15}
                    />
                  </div> */}
                <div className="mb-3">
                  <label className="form-label" htmlFor="date">
                    Description
                  </label>
                  <textarea
                    type="string"
                    name="description"
                    className={`form-control ${touched?.description && Boolean(errors.description)
                      ? "border-danger"
                      : ""
                      }`}
                    id="date"
                    placeholder="Enter title"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                  >
                    {" "}
                  </textarea>
                  <InputErrorMessage
                    error={touched.description && errors.description}
                    marginBottom={-15}
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className="btn btn-primary w-full my-6"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ProductView;
