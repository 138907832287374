import React, { useEffect, useState } from "react";
import {
  getAllPlansData,
  getDashboardDataApi,
  getOwenrDetailById,
} from "../../Apis";
import { SERVER_ENDPOINT } from "../../utils/baseurl";
import img from "../../Assets/image/img-profile.jpg";
import ReactPaginate from "react-paginate";
import moment from "moment/moment";
import Loader from "../../Components/common/loader/Loader";
import "./AddSubscription.css"

const AddSubscription = () => {
  const [dashboardData, setDashboardData] = useState("");
  const [ownerId, setOwnerId] = useState("");
  const [ownerData, setOwnerData] = useState("");
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState([]);
  const getDashboardDetails = async (page, limit) => {
    try {
      const res = await getDashboardDataApi(page, limit, "");
      if (res?.status === 200 || res?.status === 201) {
        setDashboardData(res?.data);
        setLoading(false);
        setPageCount(Math.ceil(res?.data?.allCount / limit));
      }
      console.log(res, "respsdnfknsdityuis");
    } catch (err) {
      console.log(err);
    }
  };

  const getPlansinfo = async () => {
    try {
      const res = await getAllPlansData();
      console.log(res, "response");
      if (res?.status === 200 || res?.status === 201) {
        setPlans(res.data.allPlans);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getPrice = (name) => {
    console.log(name, "namdmfksdafidsfnds");
    // console.log(d,"ADFDSAFDSFSD",plans)
    if (name) {
      let d = name?.split("+")[1]?.split(" ")[1] || 0;
      let total =
        parseInt(plans[0]?.planPriceId?.price) +
        parseInt(plans[1]?.planPriceId?.price * d);
      return total;
    } else {
      return "-";
    }
  };
  const getSinglePrice = (name) => {
    console.log(name, "namesingle");
    if (name) {
      let d = name?.split("+")[1]?.split(" ")[1] || 0;
      let total =
        parseInt(plans[0]?.planPriceId?.price) +
        parseInt(plans[1]?.planPriceId?.price * d);
      return total;
    } else {
      return "-";
    }
  };
  console.log(plans, "plandfasdi");
  const handlePageClick = (e) => {
    setLoading(true);
    const selectedPage = e.selected + 1;
    setCurrentPage(selectedPage);
    getDashboardDetails(selectedPage, limit);
  };

  const handleSearch = async (e) => {
    try {
      const response = await getDashboardDataApi(1, limit, e.target.value);
      if (response.status === 200) {
        setDashboardData(response?.data);
        setLoading(false);
        setPageCount(Math.ceil(response?.data?.allCount / limit));
      }
    } catch (err) {
      console.log(err);
    }
  };
  //   const offset = currentPage * limit;
  //   const pageCount = Math.ceil(data.length / PER_PAGE);
  const getOwnerDetails = async (id) => {
    try {
      const res = await getOwenrDetailById(id);
      console.log(res, "tiuioasdnfadsfi");
      if (res?.status === 200 || res?.status === 201) {
        setOwnerData(res?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getDashboardDetails(currentPage, limit);
    getPlansinfo();
  }, []);
  useEffect(() => {
    if (ownerId) {
      getOwnerDetails(ownerId);
    }
  }, [ownerId]);
  return (
    <>
      <main className="py-8">
        <>
          {/* {modal} */}
          <div
            className="modal fade"
            id="modalExport"
            tabIndex={-1}
            aria-labelledby="modalExport"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div className="modal-content shadow-3">
                <div className="modal-header mb-5">
                  <div className="h3 text-center"> Account Holder Info</div>
                  <button
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <hr />
                <div className="modal-body pt-0">
                  {/* Text */}
                  <div className="container-fluid max-w-screen-md vstack">
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="text-center">
                          <a
                            href="#"
                            className="avatar w-48 h-48 border border-body border-4 rounded position-relative"
                          >
                            <img
                              alt="..."
                              src={`${SERVER_ENDPOINT}/${ownerData?.owner?.profilePic}`}
                              onError={(event) => {
                                event.target.src = img;
                                event.onerror = null;
                              }}
                              className="rounded"
                            />
                          </a>
                        </div>
                      </div>

                      <div className="col-lg-9 mx-auto">
                        <div className="row">
                          <div className="col-sm-12">
                            <div class="table-responsive">
                              <table className="table table-borderless jainul-table">
                                <tbody>
                                  <tr>
                                    <th>Account Owner Name</th>
                                    <td>
                                      {ownerData?.owner &&
                                        ownerData?.owner?.salutation +
                                          " " +
                                          ownerData?.owner?.firstName +
                                          " " +
                                          ownerData?.owner?.lastName}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Date</th>
                                    <td>
                                      {ownerData?.owner &&
                                        moment(
                                          ownerData?.owner?.createdAt?.split(
                                            "T"
                                          )[0]
                                        ).format("DD/MM/YYYY")}

                                      {console.log(ownerData, "ODPDODOOD")}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Plan Name</th>
                                    <td>
                                      {ownerData?.userSubscription?.planeName}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Price</th>
                                    <td>
                                      &euro;
                                      {" " +
                                        getSinglePrice(
                                          ownerData?.userSubscription?.planeName
                                        )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Status</th>
                                    <td className="font-bolder text-success">
                                      {ownerData?.userSubscription?.status ===
                                      "complete"
                                        ? "Subscribed"
                                        : "Trial"}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="card card_latest_account">
              <div className="card-header border-bottom d-flex">
                <div style={{ width: "100%" }}>
                  <h5 className="mb-0">Subscribed Users</h5>
                </div>
                <div className="input-group input-group-sm input-group-inline me-3">
                  <span className="input-group-text pe-2">
                    <i className="bi bi-search"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    aria-label="Search"
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-hover table-nowrap">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">Sr No.</th>
                      <th scope="col">Account Owner Name</th>
                      <th scope="col">Date of Subscription</th>
                      <th scope="col">Plan name</th>
                      <th scope="col">Price</th>
                      <th scope="col ">Status</th>
                      <th className="text-end" scope="col">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr align="center">
                        <td colspan="8">
                          {" "}
                          <Loader />
                        </td>{" "}
                      </tr>
                    ) : (
                      Boolean(dashboardData?.allOwners?.length) &&
                      dashboardData?.allOwners?.map((v, i) => {
                        return (
                          <tr>
                            <td>{(currentPage - 1) * limit + (i + 1)}</td>
                            <td>
                              <img
                                alt="..."
                                src={`${SERVER_ENDPOINT}/${v?.profilePic}`}
                                onError={(event) => {
                                  event.target.src = img;
                                  event.onerror = null;
                                }}
                                className="avatar avatar-sm rounded-circle me-2"
                              />
                              <a
                                className="text-heading font-semibold"
                                href="#"
                              >
                                {v?.salutation +
                                  " " +
                                  v?.firstName +
                                  " " +
                                  v?.lastName}
                              </a>
                            </td>
                            <td>
                              <div className="avatar-group">
                                <a
                                  className="text-heading font-semibold"
                                  href="#"
                                >
                                  {moment(v?.createdAt?.split("T")[0]).format(
                                    "DD/MM/YYYY"
                                  )}
                                </a>
                              </div>
                            </td>
                            <td>
                              <a
                                className="text-heading font-semibold"
                                href="#"
                              >
                                {v?.planName ? v?.planName : "-"}
                              </a>
                            </td>
                            <td>
                              <a
                                className="text-heading font-semibold"
                                href="#"
                              >
                                &euro; {" " + getPrice(v?.planName)}
                              </a>
                            </td>
                            <td>
                              <span className="badge badge-lg badge-dot">
                                <i className="bg-success" />
                                {v?.status == "complete"
                                  ? "Subscribed"
                                  : "Trial"}
                              </span>
                            </td>
                            <td className="text-end">
                              <a
                                href="#modalExport"
                                className="btn btn-sm btn-primary border-base"
                                data-bs-toggle="modal"
                                onClick={() => setOwnerId(v?._id)}
                              >
                                <span>View</span>
                              </a>
                            </td>
                          </tr>
                        );
                      })
                    )}
                    {/* <tr>
                                            <td>1</td>
                                            <td>
                                                <img
                                                    alt="..."
                                                    src="/adminkit/img/logos/adminuser.png"
                                                    className="avatar avatar-sm rounded-circle me-2"
                                                />
                                                <a className="text-heading font-semibold" href="#">
                                                    Alexa
                                                </a>
                                            </td>
                                            <td>
                                                <div className="avatar-group">
                                                    <a className="text-heading font-semibold" href="#">
                                                        23-01-2022
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <a className="text-heading font-semibold" href="#">
                                                    Standard
                                                </a>
                                            </td>
                                            <td>
                                                <a className="text-heading font-semibold" href="#">
                                                    &euro;100
                                                </a>
                                            </td>
                                            <td>
                                                <span className="badge badge-lg badge-dot">
                                                    <i className="bg-success" />
                                                    Active
                                                </span>
                                            </td>
                                            <td className="text-end">
                                            <a
                                                    href="#modalExport"
                                                    className="btn btn-sm btn-primary border-base"
                                                    data-bs-toggle="modal"
                                                >
                                                    <span>View</span>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>
                                                <img
                                                    alt="..."
                                                    src="/adminkit/img/logos/adminuser.png"
                                                    className="avatar avatar-sm rounded-circle me-2"
                                                />
                                                <a className="text-heading font-semibold" href="#">
                                                    Alexa
                                                </a>
                                            </td>
                                            <td>
                                                <div className="avatar-group">
                                                    <a className="text-heading font-semibold" href="#">
                                                        23-01-2022
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <a className="text-heading font-semibold" href="#">
                                                    Standard
                                                </a>
                                            </td>
                                            <td>
                                                <a className="text-heading font-semibold" href="#">
                                                    &euro;100
                                                </a>
                                            </td>
                                            <td>
                                                <span className="badge badge-lg badge-dot">
                                                    <i className="bg-danger" />
                                                    Inactive
                                                </span>
                                            </td>
                                            <td className="text-end">
                                            <a
                                                    href="#modalExport"
                                                    className="btn btn-sm btn-primary border-base"
                                                    data-bs-toggle="modal"
                                                >
                                                    <span>View</span>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>
                                                <img
                                                    alt="..."
                                                    src="/adminkit/img/logos/adminuser.png"
                                                    className="avatar avatar-sm rounded-circle me-2"
                                                />
                                                <a className="text-heading font-semibold" href="#">
                                                    Alexa
                                                </a>
                                            </td>
                                            <td>
                                                <div className="avatar-group">
                                                    <a className="text-heading font-semibold" href="#">
                                                        23-01-2022
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <a className="text-heading font-semibold" href="#">
                                                    Standard
                                                </a>
                                            </td>
                                            <td>
                                                <a className="text-heading font-semibold" href="#">
                                                    &euro;100
                                                </a>
                                            </td>
                                            <td>
                                                <span className="badge badge-lg badge-dot">
                                                    <i className="bg-success" />
                                                    Active
                                                </span>
                                            </td>
                                            <td className="text-end">
                                            <a
                                                    href="#modalExport"
                                                    className="btn btn-sm btn-primary border-base"
                                                    data-bs-toggle="modal"
                                                >
                                                    <span>View</span>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>
                                                <img
                                                    alt="..."
                                                    src="/adminkit/img/logos/adminuser.png"
                                                    className="avatar avatar-sm rounded-circle me-2"
                                                />
                                                <a className="text-heading font-semibold" href="#">
                                                    Alexa
                                                </a>
                                            </td>
                                            <td>
                                                <div className="avatar-group">
                                                    <a className="text-heading font-semibold" href="#">
                                                        23-01-2022
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <a className="text-heading font-semibold" href="#">
                                                    Standard
                                                </a>
                                            </td>
                                            <td>
                                                <a className="text-heading font-semibold" href="#">
                                                    &euro;100
                                                </a>
                                            </td>
                                            <td>
                                                <span className="badge badge-lg badge-dot">
                                                    <i className="bg-success" />
                                                    Active
                                                </span>
                                            </td>
                                            <td className="text-end">
                                            <a
                                                    href="#modalExport"
                                                    className="btn btn-sm btn-primary border-base"
                                                    data-bs-toggle="modal"
                                                >
                                                    <span>View</span>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>
                                                <img
                                                    alt="..."
                                                    src="/adminkit/img/logos/adminuser.png"
                                                    className="avatar avatar-sm rounded-circle me-2"
                                                />
                                                <a className="text-heading font-semibold" href="#">
                                                    Alexa
                                                </a>
                                            </td>
                                            <td>
                                                <div className="avatar-group">
                                                    <a className="text-heading font-semibold" href="#">
                                                        23-01-2022
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <a className="text-heading font-semibold" href="#">
                                                    Standard
                                                </a>
                                            </td>
                                            <td>
                                                <a className="text-heading font-semibold" href="#">
                                                    &euro;100
                                                </a>
                                            </td>
                                            <td>
                                                <span className="badge badge-lg badge-dot">
                                                    <i className="bg-danger" />
                                                    Inactive
                                                </span>
                                            </td>
                                            <td className="text-end">
                                                <a
                                                    href="#modalExport"
                                                    className="btn btn-sm btn-primary border-base"
                                                    data-bs-toggle="modal"
                                                >
                                                    <span>View</span>
                                                </a>
                                            </td>
                                        </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="mt-6">
              <span className="text-muted text-sm">
                {/* Showing 10 items out of 250 results found */}
                <ReactPaginate
                  nextLabel="Next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={pageCount}
                  previousLabel="< Previous"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              </span>
            </div>
          </div>
        </>
      </main>
    </>
  );
};

export default AddSubscription;
