import React from "react";
import { Link, useLocation } from "react-router-dom";


const Sidebar = () => {

    const location = useLocation();
    return (
        <>
            <nav className="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg"
                id="sidebar">
                <div className="container-fluid">
                    {/* <!-- Toggler --> */}
                    <button className="navbar-toggler ms-n2" type="button" data-bs-toggle="collapse"
                        data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    {/* <!-- Brand --> */}
                    <Link className="navbar-brand py-lg-2 mb-lg-5 px-lg-6 me-0" to="/">
                        <img src="/adminkit/img/logos/Intellispine.png" alt="..." className="img-fluid" />
                    </Link>
                    {/* <!-- User menu (mobile) --> */}
                    <div className="navbar-user d-lg-none">
                        {/* <!-- Dropdown --> */}
                        <div className="dropdown">
                            {/* <!-- Toggle --> */}
                            <a href="#" id="sidebarAvatar" role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <div className="avatar-parent-child">
                                    <img alt="..." src="/adminkit/img/people/img-profile.jpg" className="avatar avatar- rounded-circle" />
                                    <span className="avatar-child avatar-badge bg-success"></span>
                                </div>
                            </a>
                            {/* <!-- Menu --> */}
                            <div className="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarAvatar">
                                <a href="#" className="dropdown-item">Profile</a>
                                <a href="#" className="dropdown-item">Settings</a>
                                <a href="#" className="dropdown-item">Billing</a>
                                <hr className="dropdown-divider" />
                                <a href="#" className="dropdown-item">Logout</a>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Collapse --> */}
                    <div className="collapse navbar-collapse" id="sidebarCollapse">
                        {/* <!-- Navigation --> */}

                        <ul className="navbar-nav">

                            <li className={`nav-item ${location.pathname == "/" ? "jainul":""} `}>
                                <Link to="/" className="nav-link" >
                                    <i className="bi bi-briefcase"></i> Dashboard
                                </Link>
                            </li>
                            <li className={`nav-item ${location.pathname == "/pages/MIS/report" ? "jainul":""} `}>
                                <Link className="nav-link" to="/pages/MIS/report" role="button">
                                    <i className="bi bi-bookmarks"></i> Report
                                </Link>

                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#sidebar-health" data-bs-toggle="collapse" role="button"
                                    aria-expanded="false" aria-controls="sidebar-health">
                                    <i className="bi bi-gear"></i> Settings
                                </a>
                                <div className="collapse " id="sidebar-health">
                                    <ul className="nav nav-sm flex-column">
                                        <li className={`nav-item ${location.pathname == "/pages/subscription/billing" ? "jainul":""} `}>
                                            <Link className="nav-link ms-3 ps-6" to="/pages/subscription/billing" role="button">
                                                <i className="bi bi-plus-square"></i> Products
                                            </Link>

                                        </li>
                                        <li  className={`nav-item ${location.pathname == "/pages/subscription/AddSubscription" ? "jainul":""} `}>
                                            <Link className="nav-link ms-3 ps-6" to="/pages/subscription/AddSubscription" role="button">
                                                <i className="bi bi-person"></i> Subscribed User
                                            </Link>

                                        </li>
                                    </ul>
                                </div>
                            </li>

                            {/* <li className="nav-item">
                            <a className="nav-link" href="#sidebar-patient" data-bs-toggle="collapse" role="button"
                                aria-expanded="false" aria-controls="sidebar-patient">
                                <i className="bi bi-briefcase"></i> Patients
                            </a>
                            <div className="collapse" id="sidebar-patient">
                                <ul className="nav nav-sm flex-column">
                                    <li className="nav-item">
                                        <a href="./Patient/pateint.html" className="nav-link">
                                            Listing
                                        </a>
                                    </li>
                                </ul>
                            </div>

                        </li>  */}
                        </ul>
                        <div className="mt-auto"></div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Sidebar