import * as Yup from "yup";

export const loginSchema = Yup.object({
    email: Yup.string().email("Please enter valid email address")
    .trim('The Email cannot include leading and trailing spaces')
    .strict(true)
    .required("Please enter your email"),
    password: Yup.string().min(6).required("Please enter your password"),
})

export const changePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().min(6).required("Old Password is required"),
    newPassword: Yup.string().min(6).required("New Password is required"),
    confirmPassword: Yup.string().required("Confirm New Password is required").when("newPassword", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("newPassword")],
        "Both password need to be the same"
      )
    })
  });

  

export const editProfileSchema = Yup.object({

    firstName: Yup.string().min(3, "Name must be atleast 5 characters")
    . max(50, "Name should not greater than 50 Characters")
    .trim('The Name cannot include leading and trailing spaces')
    .strict(true)
    .matches(/^[aA-zZ\s]*$/, "Only alphabets are allowed for this field ")
    .required("First Name is required"),

    lastName: Yup.string().min(5, "Name must be atleast 5 characters")
    . max(50, "Name should not greater than 50 Characters")
    .trim('The Name cannot include leading and trailing spaces')
    .strict(true)
    .matches(/^[aA-zZ\s]*$/, "Only alphabets are allowed for this field ")
    .required("Last Name is required"),

    salutation: Yup.string().required("Select Salutation"),

    contactNo: Yup.string().max(13, "Contact No. should not greater than 13 digits")
    .required("Invalid Phone Number")
    .trim('The Contact No. cannot include leading and trailing spaces')
    .strict(true)
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Invalid phone number"
    )
}) 

  
export const addSubscriptionSchema = Yup.object({
  name: Yup.string().required("Plan Name is required")
  .matches(/^[aA-zZ\s]*$/, "Only alphabets are allowed for this field"),
  price: Yup.string().required("Price is required"),
  description: Yup.string().required("Description is required"),
  // validTill: Yup.string().required("Duration is required")
})



