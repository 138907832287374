import React from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
  } from "recharts";
  
  export default function ReCharts() {
    const data = [
      {name:"Jan", Ongoing: 300, Finished: 500},
      {name:"Feb", Ongoing: 500, Finished: 700},
      {name:"Mar", Ongoing: 700, Finished: 300},
      {name:"Apr", Ongoing: 900, Finished: 200},
      {name:"May", Ongoing: 800, Finished: 1000 },
      {name:"Jun", Ongoing: 700,Finished:  100},
      {name:"Jul", Ongoing: 900, Finished: 400},
      {name:"Aug", Ongoing: 200, Finished: 1000},
      {name:"Sep", Ongoing: 1000, Finished: 900},
      {name:"Oct", Ongoing: 200, Finished: 1000},
      {name:"Nov", Ongoing: 400, Finished: 200 },
    ];
  
    return (
        <ResponsiveContainer width={"100%"} height={300} >
          <LineChart
            // width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 0,
              bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="4 4" stroke="#eee" />
            <XAxis dataKey="name" stroke="eee"/>
            <YAxis color="#595f60" stroke="eee" type="number" domain={['auto', 'auto']}/>
            <Tooltip />
            {/* <Legend /> */}
            <Line
              type="monotone"
              dataKey="Ongoing"
              strokeWidth={5}
              activeDot={false}
              dot={false}
              stroke="#7478f6"
            />
            <Line
              type="monotone"
              dataKey="Finished"
              strokeWidth={5}
              dot={false}
              stroke="#ff70a9"
              activeDot={false}
            />
          </LineChart>
        </ResponsiveContainer>
    );
  }
  