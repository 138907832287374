export const setToken = (token) => localStorage.setItem("token", token);

export const getToken = () => localStorage.getItem("token");

export const isAuth = () => {
 return Boolean(getToken())
}

export const setAdmin = (admin) => localStorage.setItem("admin", JSON.stringify(admin))

export const getAdmin = () => JSON.parse(localStorage.getItem("admin"))

export const removeAdmin = () => localStorage.removeItem("admin")

export const removeToken = () => localStorage.removeItem("token")

export const getLastElementByRoute = () => { 
 
 const path =  window.location.pathname.split("/")

 return path[path.length-1]

}
